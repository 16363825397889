import { createSlice } from '@reduxjs/toolkit';
import API from '../../services/api';
import {USERS_API_URL} from '../../config/service';
import { showError } from '../messages/messagesSlice';

export const csoAllUsersSlice = createSlice({
  name: 'csoallusers',
  initialState: {
    csoallusers: [],
  },
  reducers: {
    setCsoAllUsers: (state, action) => {
        if (action.payload)
        {
            state.csoallusers = action.payload;
        }
    }
  },
});

export const { setCsoAllUsers } = csoAllUsersSlice.actions;

export const csoAllUsers = () => dispatch => {
    try {
          API.get(`${USERS_API_URL}/v1/users/all`)
          .then((response) => dispatch(setCsoAllUsers(response.data)));
    }
    catch(error) {
        dispatch(showError({ "message": `List all users failed. ${error.message}` }));
    };
};

export const selectCsoAllUsers = state => state.csoallusers.csoallusers;
export default csoAllUsersSlice.reducer;
