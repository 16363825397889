import { createSlice } from '@reduxjs/toolkit';
import API from '../../services/api';
import {SURVEYS_API_URL} from '../../config/service';
import { showError } from '../messages/messagesSlice';

export const surveyListSlice = createSlice({
  name: 'surveylist',
  initialState: {
    surveylist: [],
  },
  reducers: {
    setSurveyList: (state, action) => {
        if (action.payload)
        {
            state.surveylist = action.payload;
            state.isLoadingSurveys = false;
        }
    }
  },
});

export const { setSurveyList } = surveyListSlice.actions;

export const listSurveys = () => dispatch => {
    try {
          API.get(`${SURVEYS_API_URL}/v1/surveys`)
          .then((response) => dispatch(setSurveyList(response.data.Items)));
    }
    catch(error) {
        dispatch(showError({ "message": `List surveys failed. ${error.message}` }));
    };
};

export const selectSurveys = state => state.surveylist.surveylist;
export default surveyListSlice.reducer;
