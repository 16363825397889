import { createSlice } from '@reduxjs/toolkit';
import API from '../../services/api';
import {BUSINESS_API_URL} from '../../config/service';
import { showError, showSuccess } from '../messages/messagesSlice';

export const revokeAffiliationSlice = createSlice({
  name: 'revokeaffiliation',
  initialState: {
    triggerRevokeAffiliation: false
  },
  reducers: {
    revokeAffiliation: (state, action) => {
        state.triggerRevokeAffiliation = true;
    },
    revokeAffiliationTriggered: (state, action) => {
      state.triggerRevokeAffiliation = false;
    }
  },
});

export const { revokeAffiliation, revokeAffiliationTriggered } = revokeAffiliationSlice.actions;

export const revokeAffiliationAction = username => dispatch => {
    API.delete(`${BUSINESS_API_URL}/v1/business/affiliation/remove/${username}`)
    .then((response) => {
        dispatch(showSuccess({ "message": "This affiliation has been successfully revoked." }));
    }).catch((error) => {
        dispatch(showError({ "message": `Revoke affiliation failed. ${error.message}` }));
    });
};

export const selectTriggerRevokeAffiliation = state => state.revokeaffiliation.triggerRevokeAffiliation;

export default revokeAffiliationSlice.reducer;
