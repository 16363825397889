import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import { selectUser } from '../auth/authSlice';
import { SurveyData } from '../surveydata/surveyData';
import { listUsers } from '../userlist/usersListSlice';
import { unlistSurveyUsers } from '../managesurveyusers/ManageSurveyUsersSlice';
import { SurveyManagementCso } from './SurveyManagementCso';

export function SurveyManagement() {

  const dispatch = useDispatch()
  const user = useSelector(selectUser);

  React.useEffect(() => {

    dispatch(listUsers(user.cso_identifier));
    dispatch(unlistSurveyUsers());

  }, [user, dispatch])

  let admin;
  let cso_admin;

  const userType = user.groups;

  if(userType && (userType.includes('BUSINESS_ADMINS'))) {
    admin = true
  }
  else if(userType){
    admin = false;
  }

  if(userType && (userType.includes('CSO_ADMINS'))) {
    cso_admin = true
  }
  else if(userType){
    cso_admin = false;
  }
  
  if(admin){
    return (
          <div className='feature-wrapper pb-5 mt-5 mt-lg-0'>
            <Row>
              <Col>
                <Container className= 'pt-5'>
                    <Card>
                      <Card.Header style={{backgroundColor: "#45C1C0", color: "white"}}><h3>All Surveys</h3></Card.Header>
                      <Card.Body>
                        
                          <SurveyData/>
                        
                      </Card.Body>
                      </Card>
                  </Container>
                </Col>
            </Row>
          </div>
    );
  }
  else if (cso_admin) {
    return (
      <SurveyManagementCso />
      // <div className='feature-wrapper pb-5 mt-5 mt-lg-0'>
      //         <Row>
      //           <Col>
      //             <Container fluid className= 'pt-5'>
      //                 <Card>
      //                   <Card.Header style={{backgroundColor: "#45C1C0", color: "white"}}><h3>All Surveys</h3></Card.Header>
      //                   <Card.Body>
      //                   <div style={divStyle}><CsoAllSurveys/></div>
      //                   </Card.Body>
      //                 </Card>
      //             </Container>
      //           </Col>
      //         </Row>
      // </div>
    );
  }
  else {
    return(
    <Redirect to="/homepage"/>
    )
  }
}
