import React from "react";
import { Button, Table } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import {
  Link
} from "react-router-dom";
import { selectUser } from '../auth/authSlice';
import { selectAffiliatedOrgs } from "./affiliatedOrgsListSlice";
import { listExternalUserTasks } from "../externalsurveyallocation/externalUserTasksSlice"

export const AffiliatedOrgs = () => {
  
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const affiliatedorgs = useSelector(selectAffiliatedOrgs);
  const activeorgs = affiliatedorgs.filter((item)=>item.Status.includes('ACTIVE'));
  return (    
    activeorgs.length > 0 ? (
      <Table responsive striped bordered hover size="sm">
          <thead>
         
            <tr style={{ textAlign: "center" }}>
              <td><b>Organization ID</b></td>
              <td><b>Organization</b></td>
              <td><b>Status</b></td>
              <td><b>Upload Requests</b></td>
            </tr>
          </thead>
        {activeorgs ? (
          <tbody>
            {activeorgs.map((surveyRow, key) => {

              return (
                  <tr key={key} style={{ textAlign: "center"}}>
                    <td>{surveyRow.ORGID.slice(4,)}</td>
                    <td>{surveyRow.COMPANY_NAME}</td>
                    <td>{surveyRow.Status}</td> 
                    <td>
                      <Link to={{ pathname: "/home/tasks/external", state: {cso_id: surveyRow.ORGID.slice(4,), company_name: surveyRow.COMPANY_NAME} }}>
                        <Button
                         onClick={() => (dispatch(listExternalUserTasks(user.username, surveyRow.ORGID.slice(4,))))}
                          variant="info"
                          >
                          Allocated Tasks
                        </Button>
                      </Link>
                    </td>
                  </tr>
              )
            })}
          </tbody>
        ) : (
          <div>Loading...</div>
        )}
      </Table>
    ) : (
      <div>&nbsp; You have no active affiliations.</div>
    )
  );
};


