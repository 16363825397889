import React from 'react';
import CookieConsent from "react-cookie-consent";
import { useDispatch } from 'react-redux';
import {
    accept,
    decline
} from './cookieSlice';

export function CookieModal() {
    const dispatch = useDispatch();

    return (
        <CookieConsent
            location="top"
            buttonText="Accept"
            declineButtonText="Reject"
            cookieName="CSOCookieConsent"
            style={{ background: "rgb(69, 193, 192)", fontSize: "16px" }}
            declineButtonStyle={{fontWeight: "bold", fontSize: "16px", background: "rgb(0, 111, 116)"}}
            buttonStyle={{color: "white",fontWeight: "bold",fontSize: "16px", background: "rgb(69, 193, 192)"}}
            expires={150}
            enableDeclineButton
            onAccept={() => {
                dispatch(accept());
            }}
            onDecline={() => {
                dispatch(decline());
            }}
            >
            The website uses cookies to improve your experience and to monitor the site traffic.{" "}
            <a aria-label="learn more about cookies" role="button" tabIndex="0" href="https://www.cso.ie/en/aboutus/lgdp/dataprotectionprivacytransparency/websiteprivacystatement/" rel="noopener noreferrer nofollow" target="_blank" style={{color: 'white'}} >Privacy Policy</a>
        </CookieConsent>
    );
}