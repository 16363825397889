import { createSlice } from '@reduxjs/toolkit';
import API from '../../services/api';
import {SURVEYS_API_URL} from '../../config/service';
import { showError, showSuccess } from '../messages/messagesSlice';

export const allocateSurveyExternalSlice = createSlice({
  name: 'allocatesurveyexternal',
  initialState: {
    value: '',
    allocateExternalMessage: false,
    unallocateExternalMessage: false
  },
  reducers: {
    allocateSurveyExternalStatus: (state, action) => {
      state.value = action.payload;
    },
    surveyAllocatedExternal: state => {
      state.allocateExternalMessage = true
    },
    surveyUnallocatedExternal: state => {
      state.unallocateExternalMessage = true
    },
    resetAllocatedExternalMessage: state => {
      state.allocateExternalMessage = false
    },
    resetUnallocatedExternalMessage: state => {
      state.unallocatedExternalMessage = false
    }
  },
});

export const { allocateSurveyExternalStatus, surveyAllocatedExternal, surveyUnallocatedExternal, resetAllocatedExternalMessage, resetUnallocatedExternalMessage } = allocateSurveyExternalSlice.actions;

export const allocateSurveyExternal = (username, surveyString, surveyName) => dispatch => {
    API.post(`${SURVEYS_API_URL}/v1/surveys/allocate/external/${surveyString}`, {
        username: username,
        surveyName: surveyName
      }).then((response) => {
        dispatch(showSuccess({ "message": "This survey has been allocated successfully to: " + username }));
        dispatch(surveyAllocatedExternal())
    }).catch((error) => {
        dispatch(showError({ "message": `Survey allocation failed. ${error.message}` }));
    });
};

export const selectAllocateSurveyExternalStatusSelector = state => state.allocatesurveyexternal.value;

export const selectAllocatedExternalMessage = state => state.allocatesurveyexternal.allocateExternalMessage
export const selectUnallocatedExternalMessage = state => state.allocatesurveyexternal.unallocateExternalMessage

export default allocateSurveyExternalSlice.reducer;
