import { createSlice } from '@reduxjs/toolkit';
import API from '../../services/api';
import {USERS_API_URL} from '../../config/service';
import { showError, showSuccess } from '../messages/messagesSlice';

export const makeAdminSlice = createSlice({
  name: 'makeadmin',
  initialState: {
    value: '',
  },
  reducers: {
    makeAdminStatus: (state, action) => {
      state.value = action.payload;
    }
  },
});

export const { makeAdminStatus } = makeAdminSlice.actions;

export const makeAdminUser = username => dispatch => {
    API.put(`${USERS_API_URL}/v1/users`, {
        username: username,
      }).then((response) => {
        dispatch(showSuccess({ "message": "User has successfully been made an admin." }));
    }).catch((error) => {
        dispatch(showError({ "message": `User could not be made an admin. ${error.message}` }));
    });
};

export const selectMakeAdminStatusSelector = state => state.makeadmin.value;

export default makeAdminSlice.reducer;
