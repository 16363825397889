import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


export function TermsPage() {
    return (
        <div>
        <Container>
            <Row className='pt-5'>
                <Col><ul>
                <b>Terms & Conditions:</b> <br/><br/>
<b>Introduction</b> – This data portal is operated by the Central Statistics Office (CSO) of Ireland (“Central Statistics Office”, “we”, “us”, or “our”).  These terms of service (“Terms”) cover the access to and use of the Portal by any person or Enterprise that interacts with us.  By using or accessing the Portal, you agree to be bound by these Terms.  If you are using or accessing this Portal on behalf of another organisation or Enterprise, you agree to these Terms on behalf of that Organisation or Enterprise (for the purposes of this document, any reference to Enterprise will also refer to Organisation).

<br/><br/><b>Using the Portal</b> – You can access and use the Portal to communicate and exchange information with us.  You cannot access or use it for any other purpose.  The Portal allows you to access, upload documents and data, access & complete CSO surveys and to provide information (“Material”). 
<br/><br/>The Portal and its features will require our systems to access, store and scan your Material.  By using the Portal you are giving us permission to process your Materials and as such you give us permission to view, read, edit, copy, store and use the Material for statistical purposes as set out in the Statistics Act, 1993.  Your provision of Material for statistical purposes is a requirement where collection is carried out under Sections 11 and 30 of the Act

<br/><br/><b>Your Responsibilities</b> – You must comply with these Terms, including our Acceptable Use Terms, set out below.  To use the Portal you must create a password and supply us with an email address as part of the registration process.  
<br/><br/>Your password is personal to you (Enterprise) and you must not let anyone else use it.  Each Enterprise is responsible for ensuring its Portal Administrator, whether internal or external to the Enterprise, adheres to these Terms.  Each Enterprise will have at least one Portal Administrator who is responsible for the Enterprise’s use of the Portal, including the management of the Enterprise’s users, access and privileges.  
<br/><br/>You must ensure the Material displayed on the Portal about your Enterprise is accurate and up-to-date.  
<br/><br/>You are responsible for all activities occurring under your account and for keeping your Password secure.  Do not share your password or use it elsewhere.  You are also responsible for ensuring your second factor authentication method (email) is kept secure.  You agree to notify your Portal Administrator immediately of any unauthorised use of your account.<br/><br/>

<b>Privacy and Data Protection </b> - The CSO fully respects your privacy and data protection rights.  Any personal data which you provide to the CSO will be treated with the highest standard of security and confidentiality, strictly in accordance with the principles of the General Data Protection Regulation.  All information supplied to the CSO is treated as strictly confidential.  The Statistics Act, 1993 sets stringent confidentiality standards; information collected may be used only for statistical purposes, and no details that might be related to an identifiable person or business undertaking may be divulged to any other government department or body.
<br/><br/>Our Portal web site contains links to other sites.  The CSO is not responsible for the privacy practices within any of these other sites.  We encourage you to be aware of this when you leave our site and to read the privacy statements on other web sites you visit which collect personal data.  This privacy statement applies solely to information collected on our web site.
<br/><br/>Any Materials uploaded or survey data submitted via the Portal is transmitted to us via a secure socket layer (SSL) and Secure File Transfer Protocol (SFTP) connections.  We hold these details for the length of time necessary to process the data and only use the details for the purpose for which it was obtained i.e. the processing of statistics.  This Material will not be available in the Portal, only a record of the upload or submission is available to view in the Portal.
<br/><br/><b>Confidentiality</b> - All information supplied to the CSO is treated as strictly confidential in accordance with National and EU Legislation.
The information will be used for statistical purposes only and will not be divulged to any other Government Department or body.<br/><br/>

<b>Termination</b> – We have the right to suspend, restrict or terminate your access to and use of the Portal and/or your account with or without notice to you if we consider that:<br/><br/>
<li>	You are in breach of these Terms </li>
<li>	You are using the Portal in a manner that would cause a risk or harm or loss to us </li>
<li>	You have not accessed or used the Portal for what we consider to be an extended period of time or;</li>
<li>	If we are no longer making the Portal available</li><br/><br/>

<b>Acceptable Usage </b>– You are responsible for all Material uploaded to the Portal using your account, even if the Material was assembled by someone else.  All Material uploaded using your account must comply with applicable law in Ireland and in the EU.  
You must not upload or share Material that: <br/><br/>
<li>	is defamatory against any person or business or impinges upon a person’s or business’ good name or reputation</li>
<li>	is offensive, sexist, homophobic, obscene, hateful or inflammatory, harassing, bullying or threatening to any person</li>
<li>	may be likely to bully, harass, upset, embarrass, alarm or annoy any other person or business</li>
<li>	promotes or contains sexually explicit material</li>
<li>	is in breach of (i) anyone's privacy or data protection rights or (ii) confidentiality</li>
<li>	infringes any third party’s intellectual property rights</li>
<li>	may prejudice legal proceedings</li>
<li>	may be likely to deceive any person</li>
<li>	promotes any illegal activity</li>
<li>	that is used to impersonate any person, or to misrepresent your identity or affiliation with any person </li>
<li>	infringe any applicable law (e.g. copyright laws) when using the Portal </li>
<li>	seeks to use the Portal in a malicious way or in any way that could damage, disable, overburden, impair or compromise in any manner the Portal, its security or operability or our systems or security or interfere with other users </li>
<li>	seeks to circumvent the limitations of your user rights. </li>
<br/>We reserve the right to determine whether there has been a breach of these Terms of Use through your use of the Portal.<br/><br/>

<b>General</b> – You may not link to the CSO Portal nor establish a link in such a way as to suggest any form of association or endorsement on our part.
<br/><br/>We reserve the right to change these Terms at any time and will inform you of any such changes by a notification to the email address associated with your account.  By using the Portal after the specified date of such changes, you agree to be legally bound by the amended Terms.<br/><br/>

<b>Contact us </b>– To contact us, please email collectexternal@cso.ie<br/><br/>

<b>Appendix A – Services available via the Portal </b>
<br/>The services available via the Portal are as follows:<br/>
<li>	Upload facility for use with Household Budget Survey receipts including .jpg files</li>
<li>	Upload facility for use with business surveys including xml and excel documents</li>
<li>	Submission of business and other survey data via eQ Digital Data Collection Application</li>
<li>	View record of uploaded documents and submitted surveys but no actual document/data</li>
<li>	Allow delegation of the account and/or CSO Surveys to a person/accountant/payroll provider by the Enterprise</li>
<li>	Allow the account to generate an email message to the CSO via the Portal</li><br/>

<b>Appendix B – Portal Administrator </b>
<br/>All Enterprises/Households who use the Portal must have a Portal Administrator in place.  The Portal Administrator is responsible for the Enterprise/Household’s overall use of the Portal, and in particular for the activities of the other Enterprise/Household users to whom they grant access and permission(s). The Portal Administrator will be responsible for, but not limited to, the following:<br/>
<li>	Granting access to users for the Enterprise which they administer</li>
<li>	Ensuring access is limited to those who require such access for their role</li>
<li>	Granting specific portal permissions to users</li>
<li>	Ensuring users have the appropriate permissions for their role</li>
<li>	Amending portal permissions for users</li>
<li>	Revoking access from users</li>
<li>	Providing guidance to Enterprise users on the optimal access to and use of the Portal</li>
<li>	Confirming to the CSO, on request, that the Enterprise is compliant with user attestation policies and procedures</li>
<li>	Confirming to the CSO, on request, that the Enterprise is compliant with the terms of use of the Portal as set out herein. </li>
The above is not an exhaustive list of the roles and responsibilities of the Portal Administrator.

                    </ul>
                </Col>
            </Row>
        </Container>
        </div>
  );
}