import { createSlice } from '@reduxjs/toolkit';
import API from '../../services/api';
import {SURVEYS_API_URL} from '../../config/service';
import { showError } from '../messages/messagesSlice';

export const csoAllSurveysSlice = createSlice({
  name: 'csoallsurveys',
  initialState: {
    csoallsurveys: [],
  },
  reducers: {
    setCsoAllSurveys: (state, action) => {
        if (action.payload)
        {
            state.csoallsurveys = action.payload;
        }
    }
  },
});

export const { setCsoAllSurveys } = csoAllSurveysSlice.actions;

export const csoAllSurveys = () => dispatch => {
    try {
          API.get(`${SURVEYS_API_URL}/v1/surveys/all/cso`)
          .then((response) => dispatch(setCsoAllSurveys(response.data)));
    }
    catch(error) {
        dispatch(showError({ "message": `List all surveys failed. ${error.message}` }));
    };
};

export const selectCsoAllSurveys = state => state.csoallsurveys.csoallsurveys;
export default csoAllSurveysSlice.reducer;
