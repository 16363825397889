import { createSlice } from '@reduxjs/toolkit';

export const cookieSlice = createSlice({
  name: 'cookies',
  initialState: {
    enabled: true,
  },
  reducers: {
    accept: state => {
      state.enabled = true;
    },
    decline: state => {
      state.enabled = false;
    }
  },
});

export const { accept, decline } = cookieSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const isCookieEnabled = state => state.cookies.enabled;

export default cookieSlice.reducer;
