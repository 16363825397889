import {LAUNCH_URL } from '../../config/service';
import {ACCOUNT_URL } from '../../config/service';

export function LaunchSurveyData ([csonbr, username, pin])  {

    var dt = new Date();
    dt.setDate(dt.getDate() + 7);
    const formattedString = dt.toISOString().replace(/(\.\d*)/, '').replace(/Z/, '+00:00');
    function uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        });
      }

    const formData = new FormData();
    formData.append("account_service_url", ACCOUNT_URL);
    formData.append("account_service_log_out_url", ACCOUNT_URL);
    formData.append("action_launch","Oscail Suirbhé / Open Survey");
    formData.append("case_id",uuidv4());
    formData.append("collection_exercise_sid",uuidv4());
    formData.append("exp",1800);
    formData.append("language_code","en");
    formData.append("pin",pin);
    formData.append("response_expires_at",formattedString);
    formData.append("response_id",csonbr+pin);
    formData.append("ru_ref", csonbr);
    formData.append("version","v2");
    formData.append("user_id",username);
    let urlEncodedString = "";
    for (const [key, value] of formData.entries()) {
        if (urlEncodedString !== "") {
            urlEncodedString += "&";
        }
        urlEncodedString += encodeURIComponent(key) + "=" + encodeURIComponent(value);
    }
    
    fetch(LAUNCH_URL, {
        method: "POST",
        body: urlEncodedString,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    })
    .then((response) => {
        if (response.status === 200) {
            return response.text();
          } else {
            throw new Error('Request failed with status ' + response.status);
          }
    }).then(data => {
        window.open(data);
    })
    .catch(error => {
        console.error(error);
    });
}
