import React, { useState } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { Form, Col, Container, Row, Button, Card, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectSurveyUsers, listSurveyUsers } from '../managesurveyusers/ManageSurveyUsersSlice';
import { selectSurveys } from "../surveylist/surveyListSlice";
import { selectUsers } from '../userlist/usersListSlice';
import { selectUser } from '../auth/authSlice';
import { selectCsoAllSurveys } from '../csoallsurveys/csoAllSurveysSlice';
import { allocateSurvey, resetAllocatedMessage, selectAllocatedMessage } from '../allocatesurvey/allocateSurveySlice';
import { resetUnallocatedMessage, selectUnallocatedMessage } from '../allocatesurvey/allocateSurveySlice';
import { unallocateSurvey } from '../allocatesurvey/unallocateSurvey';

export const ManageSurveyUsers = () => {
    
    const csoSurveyData = useSelector(selectCsoAllSurveys);
    const nonCsoSurveyData = useSelector(selectSurveys);
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector(selectUser);
    let surveys;
    let selectedSurveyPK;
    let selectedSurveyName;

    const allocateSuccess = useSelector(selectAllocatedMessage)
    const unallocateSuccess = useSelector(selectUnallocatedMessage)

    const [allocateUsernameState, setAllocateUsernameState] = useState('')

    const getAllocateUsername = (e) => {
        const allocateUsernameValue = e.target.value;
        setAllocateUsernameState(allocateUsernameValue)
      };

      const [unallocateUsernameState, setUnallocateUsernameState] = useState('')

      const getUnallocateUsername = (e) => {
          const unallocateUsernameValue = e.target.value;
          setUnallocateUsernameState(unallocateUsernameValue)
        };

    React.useEffect(() => {
  
      dispatch(listSurveyUsers(selectedSurveyPK.split('#')[1]));
  
    }, [selectedSurveyPK, dispatch])
  
    const userType = user.groups;
  
    if(userType && (userType.includes('BUSINESS_ADMINS'))) {
      surveys = nonCsoSurveyData;
    }
  
    if(userType && (userType.includes('CSO_ADMINS'))) {
      surveys = csoSurveyData
    }

    const location = useLocation();
    const selectedSurvey = location.pathname.split('manage/')[1]
    const surveyUsersList = useSelector(selectSurveyUsers);
    const users = useSelector(selectUsers);
    let myUsers = []
    let assignedUsers = []
    let unassignedUsers = []

    function byKeyPK(arr, key) {
        for ( var i=0, L=arr.length; i<L; i++ ) {
          if ( arr[i].survey_id === key ) {
            return arr[i].PK;
          }
        }
      }

    function byKeyName(arr, key) {
      for ( var i=0, L=arr.length; i<L; i++ ) {
        if ( arr[i].survey_id === key ) {
          return arr[i].author_title;
        }
      }
    }

    function splitUsers(surveyUsers, orgUsers)
    {
        for( var i=0, L=orgUsers.length; i<L; i++ ) {
            if ( surveyUsers.includes(orgUsers[i]) ) {
              assignedUsers.push(orgUsers[i])
            }
            else{
                unassignedUsers.push(orgUsers[i])
            }
          }
    }

    for (var i=0, L=users.length; i<L; i++ )
    {
       myUsers.push((users[i].Username))
    }

    if(selectedSurvey && surveys)
    {
        selectedSurveyPK = byKeyPK(surveys, selectedSurvey)
        selectedSurveyName = byKeyName(surveys, selectedSurvey)
    }

    splitUsers(surveyUsersList, myUsers)

    return (
       <div style={{display:"flex", padding:"3rem 2rem 0 2rem"}} className="allocate-user">
        <Container>
            <Card>
            <Card.Header style={{backgroundColor: "#004F50", color: "white"}}><h3>Allocate Users</h3></Card.Header>
            <Card.Body>
                <Form>
                <Form.Group as={Row}>
                    <Form.Label column sm="8">
                    Survey Title
                    </Form.Label>
                    <Col md="6" sm="12" lg="6">
                    <Form.Control type="text" readOnly defaultValue={selectedSurveyName}/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm="8">
                        Unassigned Users
                    </Form.Label>
                    <Col md="6" sm="12" lg="6">
                        <Form.Control 
                            as="select"
                            className="mr-sm-2"
                            id="username"
                            custom
                            onChange={getAllocateUsername}
                            value={allocateUsernameState}>

                                    <option value={0}>Select User...</option>
                            {unassignedUsers ? (
                                unassignedUsers.map((unassignedUsers, key) => {
                                return (
                                    <option key={key+1} value={key+1}>{unassignedUsers}</option>
                                    )
                                }) ) : ("")}
                        </Form.Control>
                    </Col>
                </Form.Group>
                    <div style={{float: "right"}} className="pt-2 pr-3">
                        <Button 
                            disabled={(allocateUsernameState) < 1} 
                            onClick={() => (dispatch(allocateSurvey(unassignedUsers[allocateUsernameState-1], selectedSurveyPK.split('#')[1], selectedSurveyName)))}
                            variant="info"
                            >
                            Allocate User
                        </Button>
                    </div>
                    <div>
                      <Alert show={allocateSuccess} variant="success">
                        <Alert.Heading>Thanks!</Alert.Heading>
                          <p>
                            We have sent an email to this user informing them of this allocation.
                            <br></br>
                            The lists of users on this page will be updated to reflect this allocation.
                          </p>
                          <div>
                            <Button onClick={() => {
                              dispatch(resetAllocatedMessage())
                              setAllocateUsernameState('')
                              dispatch(listSurveyUsers(selectedSurveyPK.split('#')[1]));
                              splitUsers(surveyUsersList, myUsers)
                              }}
                              variant="success">
                              Allocate another user
                            </Button>
                          </div>
                      </Alert>
                    </div>
                </Form>
            </Card.Body>
            </Card>
        </Container>
           
        <Container className="user-action">
            <Card>
            <Card.Header style={{backgroundColor: "#FBAA34", color: "white"}}><h3>Unallocate Users</h3></Card.Header>
            <Card.Body>
                <Form>
                <Form.Group as={Row}>
                    <Form.Label column sm="8">
                    Survey Title
                    </Form.Label>
                    <Col md="6" sm="12" lg="6">
                    <Form.Control type="text" readOnly defaultValue={selectedSurveyName}/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm="8">
                        Assigned Users
                    </Form.Label>
                    <Col md="6" sm="12" lg="6">
                        <Form.Control 
                            as="select"
                            className="mr-sm-2"
                            id="username"
                            custom
                            onChange={getUnallocateUsername}
                            value={unallocateUsernameState}>

                                    <option value={0}>Select User...</option>
                            {assignedUsers ? (
                                assignedUsers.map((assignedUsers, key) => {
                                return (
                                    <option key={key+1} value={key+1}>{assignedUsers}</option>
                                    )
                                }) ) : ("")}
                        </Form.Control>
                    </Col>
                </Form.Group>
                    <div style={{float: "right"}} className="pt-2 pr-3">
                        <Button 
                            disabled={(unallocateUsernameState) < 1} 
                            onClick={() => (dispatch(unallocateSurvey(assignedUsers[unallocateUsernameState-1], selectedSurveyPK.split('#')[1], selectedSurveyName)))}
                            variant="danger"
                            >
                            Unallocate User
                        </Button>
                    </div>
                    <div>
                      <Alert show={unallocateSuccess} variant="success">
                        <Alert.Heading>Thanks!</Alert.Heading>
                          <p>
                            We have sent an email to this user informing them of this unallocation.
                            <br></br>
                            The lists of users on this page will be updated to reflect this unallocation.
                          </p>
                          <div>
                            <Button onClick={() => {
                              dispatch(resetUnallocatedMessage())
                              setUnallocateUsernameState('')
                              dispatch(listSurveyUsers(selectedSurveyPK.split('#')[1]));
                              splitUsers(surveyUsersList, myUsers)
                              }}
                              variant="success">
                              Unallocate another user
                            </Button>
                          </div>
                      </Alert>
                    </div>
                </Form>
            </Card.Body>
            </Card>
          <div style={{float: "right"}} className="pt-2 pr-2">
            <Button
              onClick={() => history.goBack()}
              variant='info'
              >Back
            </Button>
          </div>
        </Container>
       </div>
        
    )
}