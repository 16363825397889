import { createSlice } from '@reduxjs/toolkit';
import API from '../../services/api';
import {SURVEYS_API_URL} from '../../config/service';
import { showError } from '../messages/messagesSlice';

const initialState = {
  surveyusers: []
}

export const surveyUsersSlice = createSlice({
  name: 'surveyusers',
  initialState,
  reducers: {
    setSurveyUsers: (state, action) => {
      if (action.payload)
      {
         state.surveyusers = action.payload;
      }
    },
    resetSurveyUsers: state => initialState
  },
});

export const { setSurveyUsers, resetSurveyUsers } = surveyUsersSlice.actions;

export const unlistSurveyUsers = () => dispatch => {dispatch(resetSurveyUsers())};

export const listSurveyUsers = (surveyString) => dispatch => {
    try {
        API.get(`${SURVEYS_API_URL}/v1/surveys/users/${surveyString}`)
        .then(async function(response) {
            if(response.data)
            {
                const detailedArray = response.data;
                let userArray = []
                if(response.data.length > 0)
                {
                  for ( var i=0, L=detailedArray.length; i<L; i++ )
                  {
                      await userArray.push((detailedArray[i].PK).split('USER#')[1])
                  }
                }
                await dispatch(setSurveyUsers(userArray))
            }
        });
    }
    catch(error) {
        dispatch(showError({ "message": `Survey users retrieval failed. ${error.message}` }));
    };
};

export const selectSurveyUsers = state => state.surveyusers.surveyusers;

export default surveyUsersSlice.reducer;
