import { createSlice } from '@reduxjs/toolkit';
import API from '../../services/api';
import {BUSINESS_API_URL} from '../../config/service';
import { showError, showSuccess } from '../messages/messagesSlice';

export const externalSignUpRequestsSlice = createSlice({
  name: 'externalsignuprequests',
  initialState: {
    externalsignuprequests: [],
  },
  reducers: {
    setExternalSignUpRequests: (state, action) => {
        if (action.payload)
        {
            state.externalsignuprequests = action.payload;
            state.isLoadingRequests = false;
        }
    }
  },
});

export const { setExternalSignUpRequests } = externalSignUpRequestsSlice.actions;

export const listExternalSignUpRequests = () => dispatch => {
    try {
          API.get(`${BUSINESS_API_URL}/v1/business/requests/external`)
          .then((response) => dispatch(setExternalSignUpRequests(response.data.Items)));
    }
    catch(error) {
        dispatch(showError({ "message": `List requests failed. ${error.message}` }));
    };
};

export const archiveExternalSignUpRequest = (email) => dispatch => {
    API.put(`${BUSINESS_API_URL}/v1/business/requests/external`, {
        email: email
    }).then(function(response) {
            dispatch(showSuccess({ "message": `Sign-up request record has been updated successfully.` }));
            listExternalSignUpRequests();
    }).catch((error) => {
        dispatch(showError({ "message": `Sign-up request record could not be updated.` }));
    });
};

export const selectExternalSignUpRequests = state => state.externalsignuprequests.externalsignuprequests;
export default externalSignUpRequestsSlice.reducer; 
