import axios from 'axios';
import { baseURL } from '../config/service';
import { Auth } from 'aws-amplify';

//axios initialisation
const API = axios.create({
    baseURL: baseURL,
    headers: {
        common: {       
         'Strict-Transport-Security': 'max-age=63072000; includeSubDomains; preload',
         'Content-Security-Policy' : "default-src 'self'",
         'X-Frame-Options' : 'deny',
         'Expect-CT' : 'max-age=86400, enforce',
         'Cache-Control' : 'no-store',
         'Pragma' : 'no-cache',
         'Feature-Policy' : "fullscreen 'self';vibrate 'none';payment 'none';sync-xhr 'none';accelerometer 'none';ambient-light-sensor 'none';autoplay 'none';camera 'none';geolocation 'none';microphone 'none';vr 'none'"
        }
      }
});

//Axios interceptors
API.interceptors.request.use( (config) => {
    return new Promise((resolve, reject) => {
        Auth.currentSession().then((session) => {
            
            const idTokenExpire = session.getIdToken().getExpiration();
            const refreshToken = session.getRefreshToken();
            const currentTimeSeconds = Math.round(+new Date() / 1000);
            if (idTokenExpire < currentTimeSeconds) {
                Auth.currentAuthenticatedUser()
                .then((res) => {
                    res.refreshSession(refreshToken, (err, data) => {
                        if (err) {
                            Auth.signOut()
                        } else {

                            config.headers.Authorization = "Bearer " + data.getIdToken().getJwtToken();
                            resolve(config);
                        }
                    });
                });
            } else {
                config.headers.Authorization = "Bearer " + session.getIdToken().getJwtToken();
                resolve(config);
            }
        })
        .catch(() => {
            // No logged-in user: don't set auth header
            resolve(config);
        });
    });
});

export default API;