import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Col, Container, Row, Button, Alert, OverlayTrigger, Popover } from 'react-bootstrap';
import {
  Redirect,
  Link
} from "react-router-dom";
import Card from 'react-bootstrap/Card';
import { selectUser } from '../auth/authSlice';
import { searchExternalUser, resetUIMessage, selectUserFound, selectExternalUsersFound, selectShowAlert, selectExternalUserAllocated, listExternalUserAllocated} from './userManagementSlice';
import { requestAffiliation } from '../useraffiliation/userAffiliationSlice';
import {useDispatch} from 'react-redux';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { listExternalUserTasks, unlistExternalUserTasks} from "../externalsurveyallocation/externalUserTasksSlice";
import { listExternalSignUpRequests, selectExternalSignUpRequests, archiveExternalSignUpRequest } from '../externalsignuprequests/externalSignUpRequestsSlice';

export function ExternalUserManagement() {

  const user = useSelector(selectUser);
  const success = useSelector(selectUserFound)
  const showUserNotFoundAlert = useSelector(selectShowAlert)
  const [emailState, setEmailState] = useState('');
  const externaluserlist = useSelector(selectExternalUserAllocated);
  const externalusersfound = useSelector(selectExternalUsersFound);
  const externalsignuprequests = useSelector(selectExternalSignUpRequests);
  const [pointerState, setPointerState] = useState('auto');

  const dispatch = useDispatch();

  const getEmail = (e) => {
    const emailValue = e.target.value;
    setEmailState(emailValue)
  };

  function dateFormat(longDate) {
    if (longDate && longDate !== "" && longDate !== " ") {
      const unixStamp = Date.parse(longDate);
      const newDate = new Date(unixStamp)
      //const readableDate = newDate
      const dateString = newDate.getDate().toString() +
        "/" + (newDate.getMonth() + 1).toString() +
        "/" + newDate.getFullYear().toString()

      return dateString;
    }
    else return null;
  }

  React.useEffect(() => {
    dispatch(resetUIMessage())
    dispatch(unlistExternalUserTasks())
    dispatch(listExternalUserAllocated())
    dispatch(listExternalSignUpRequests())
  }, [dispatch]);

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  function resetPointer(){
    sleep(2000).then(() => { setPointerState('auto') });
  }

  const userType = user.groups && user.groups.toString();
  let admin;
  if(typeof userType !== "undefined")
  {
    const strIndex = userType.indexOf('ADMINS');
    if(strIndex === -1) {
      admin = false;
    } else {
      admin = true;
    }
  } 

  if(admin){
    return (
      <div style={{padding:"3rem 2rem 0 2rem"}}>
        <Row>
          <Col>
            <Container>
              <Card>
                <Card.Header style={{backgroundColor: "#1D345C", color: "white"}}>
                  <h3 style={{textAlign:'left', float:'left'}}>Search External Users</h3>
                  <OverlayTrigger
                      placement="top"
                      delay={{ show: 150, hide: 300 }}
                      overlay={(
                      <Popover id="popover-basic">
                          <Popover.Content>
                              An external user is a user not belonging to your organisation that you can grant file upload access to.
                              Use this form to search for registered external users via email address.
                          </Popover.Content>
                      </Popover>
                      )} >
                      <h5 style={{textAlign:'right', float:'right'}}><FontAwesomeIcon icon={faInfoCircle}/></h5>
                  </OverlayTrigger>
                </Card.Header>
                <Card.Body>
                <Form>
                    <Form.Group as={Row} controlId="userEmail">
                      <Form.Label column sm="8">
                        Email Address
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control 
                          disabled= {success || showUserNotFoundAlert}
                          type="text" 
                          onChange={getEmail}
                          value={emailState}
                        />
                      </Col>
                    </Form.Group>
                    <div style={{float: "right"}} className="pt-2 pr-3">
                        <Button 
                          disabled={emailState ===""} 
                          onClick={() => {
                            dispatch(searchExternalUser(emailState.trim()));
                            }}
                          variant="info">Search for External User</Button>
                    </div>
                    <div>
                      <Alert show={success} variant="success">
                        <Alert.Heading>Found!</Alert.Heading>
                          <p>
                            At least one external user is registered with this email address.
                            <br></br>
                            <b>{emailState}</b>
                            <br></br>
                            <br></br>
                            <Table responsive striped bordered hover size="sm" variant="light">
                            <thead>
                              <tr>
                                <td><b>Username</b></td>
                                <td><b>Email</b></td>
                                <td><b>Name</b></td>
                                <td><b>Company</b></td>
                                <td><b>Affiliate</b></td>
                              </tr>
                            </thead>
                            <tbody>
                              {externalusersfound.map((userRow, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{userRow.Username}</td>
                                    <td>{userRow.Email}</td>
                                    <td>{userRow.Name}</td>
                                    <td>{userRow.Company}</td>
                                    <td>
                                      <div style={{ textAlign: "center" }}>
                                        <Button onClick={() => {
                                          dispatch(requestAffiliation(userRow.Username))
                                          dispatch(resetUIMessage())
                                          setEmailState('')
                                          }}
                                          variant="success">
                                          Request
                                        </Button>
                                      </div>
                                    </td>
                                  </tr>
                                  )
                              })}
                            </tbody>
                            </Table>
                            Click on "Request" to send an affiliation request to a user.
                            <br></br>This will not grant them any explicit permissions but will allow tasks to be assigned them if they accept.
                            <br></br>Your contact details will be included in the request.
                          </p>
                          <div className="pt-2 pr-3">
                            <Button onClick={() => {
                                dispatch(resetUIMessage())
                                setEmailState('')
                                }}
                                variant="danger">
                                Reset Form
                            </Button>
                          </div>
                      </Alert>
                      <Alert show={showUserNotFoundAlert} variant="info">
                        <Alert.Heading>External user not found</Alert.Heading>
                          <p>
                            No external user is registered with this email address.
                            <br></br>
                            <b>{emailState}</b>
                            <br></br>
                            <br></br>
                            Click on "Request Sign-Up" to send a sign-up request to this email.
                            <br></br>Your contact details will be included in the request and you must include a message.
                          </p>
                            <div>
                              <Link to={{ pathname: "/home/contact/external", state: { email: emailState } }}>
                                <Button onClick={() => {
                                  dispatch(resetUIMessage())
                                  setEmailState('')
                                  }}
                                  variant="info">
                                  Request Sign-Up
                                </Button>
                              </Link>
                            </div>
                            <div className="pt-2 pr-3">
                              <Button onClick={() => {
                                  dispatch(resetUIMessage())
                                  setEmailState('')
                                  }}
                                  variant="danger">
                                  Reset Form
                              </Button>
                            </div>
                      </Alert>
                    </div>
                </Form>
                </Card.Body>
              </Card>
            </Container>
              </Col>
            <Col>
            <Container className="user-action">
                <Card>
                  <Card.Header style={{backgroundColor: "#1D345C", color: "white"}}>
                    <h3 style={{textAlign:'left', float:'left'}}>Affiliated External Users</h3>
                    <h5 style={{textAlign:'right', float:'right'}}><FontAwesomeIcon style={{cursor: 'pointer', pointerEvents: pointerState }} 
                      onClick={() => {
                        setPointerState('none')
                        dispatch(listExternalUserAllocated())
                        dispatch(listExternalSignUpRequests())
                        resetPointer()
                        }}
                      icon={faSync}/></h5>
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 150, hide: 300 }}
                      overlay={(
                      <Popover id="popover-basic">
                          <Popover.Content>
                              These are users that have been sent an affiliation request by your organisation. Users with an ACTIVE status have accepted the request, while users with a PENDING status have yet to respond.
                          </Popover.Content>
                      </Popover>
                      )} >
                      <h5 style={{textAlign:'right', float:'right'}}><FontAwesomeIcon icon={faInfoCircle}/> &nbsp; &nbsp; </h5>
                  </OverlayTrigger>
                  </Card.Header>
                {externaluserlist ?
                  <Card.Body>
                  <Table responsive striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <td><b>Username</b></td>
                        <td><b>Email</b></td>
                        <td><b>Status</b></td>
                        <td><b>User Actions</b></td>
                      </tr>
                    </thead>
                    {externaluserlist.length > 0 ? (
                      <tbody>
                        {externaluserlist.map((userRow, key) => {
                          return (
                            <tr key={key}>
                              <td>{userRow.Username}</td>
                              <td>{userRow.email}</td>
                              <td>{userRow.Status}</td>
                              <td>
                                <Link to={{ pathname: "/home/allocate/external", state: { username: userRow.Username } }}>
                                  <div style={{ textAlign: "center" }} >
                                    <Button onClick={() => {
                                      dispatch(listExternalUserTasks(userRow.Username, user.cso_identifier))
                                      }}
                                      variant="info"
                                      disabled = {userRow.Status!== "ACTIVE"}>
                                      User Actions
                                    </Button>
                                  </div>
                                </Link>
                              </td>
                            </tr>
                            )
                        })}
                      </tbody>
                    ) : (
                      <tbody>
                        <div>You currently have no affiliated external users.</div>
                      </tbody>
                    )}
                </Table>
                  </Card.Body> : <Card.Body>
                    Loading...
                  </Card.Body>}

                </Card>
            </Container>
            <Container className="emails-sent pt-5">
                <Card>
                  <Card.Header style={{backgroundColor: "#1D345C", color: "white"}}>
                    <h3 style={{textAlign:'left', float:'left'}}>Sign-Up Requests Sent</h3>
                    <h5 style={{textAlign:'right', float:'right'}}><FontAwesomeIcon style={{cursor: 'pointer', pointerEvents: pointerState }} 
                      onClick={() => {
                        setPointerState('none')
                        dispatch(listExternalSignUpRequests())
                        dispatch(listExternalUserAllocated())
                        resetPointer()
                        }}
                      icon={faSync}/> </h5>
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 150, hide: 300 }}
                      overlay={(
                      <Popover id="popover-basic">
                          <Popover.Content>
                              These are users that have not been sent an affiliation request by your organisation but have been sent sign-up instructions via email.
                          </Popover.Content>
                      </Popover>
                      )} >
                      <h5 style={{textAlign:'right', float:'right'}}><FontAwesomeIcon icon={faInfoCircle}/> &nbsp; &nbsp; </h5>
                  </OverlayTrigger>
                  </Card.Header>
                  {externalsignuprequests ?
                  <Card.Body>
                  <Table responsive striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <td><b>Email</b></td>
                        <td><b>Username</b></td>
                        <td><b>Requested By</b></td>
                        <td><b>Date Requested</b></td>
                        <td><b>Status</b></td>
                        <td>
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 150, hide: 300 }}
                            overlay={(
                              <Popover id="popover-basic">
                                <Popover.Content>
                                  Send an affiliation request to this user.
                                  This will not grant them any explicit permissions but will allow tasks to be assigned them if they accept.
                                  Your contact details will be included in the request.
                                  Only users with a status of REGISTERED can be sent an afffiliation request.
                                </Popover.Content>
                              </Popover>
                            )} >
                            <div>
                              <b>Affiliate</b> <FontAwesomeIcon icon={faInfoCircle} />
                            </div>
                          </OverlayTrigger>
                        </td>
                        <td>
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 150, hide: 300 }}
                            overlay={(
                              <Popover id="popover-basic">
                                <Popover.Content>
                                  Archiving a record from this table just hides the row from being displayed, this does not send any further communication to the user.
                                </Popover.Content>
                              </Popover>
                            )} >
                            <div>
                              <b>Archive</b> <FontAwesomeIcon icon={faInfoCircle} />
                            </div>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    </thead>
                    {externalsignuprequests.length > 0 ? (
                      <tbody>
                        {externalsignuprequests.map((userRow, key) => {
                          return (
                            <tr key={key}>
                              <td>{userRow.PK.split('USERREGISTER#')[1]}</td>
                              <td>{userRow.Username}</td>
                              <td>{userRow.Requestor}</td>
                              <td>{dateFormat(userRow.Date)}</td>
                              <td>{userRow.Status}</td>
                              <td>
                                <div style={{ textAlign: "center" }} >
                                  <Button onClick={() => {
                                      dispatch(requestAffiliation(userRow.Username))
                                    }}
                                    disabled = {!userRow.Username && userRow.Status !== "REGISTERED"}
                                    variant="info">
                                    Request
                                  </Button>
                                </div>
                              </td>
                              <td>
                                <div style={{ textAlign: "center" }} >
                                  <Button onClick={() => {
                                      dispatch(archiveExternalSignUpRequest(userRow.PK.split('USERREGISTER#')[1]))
                                    }}
                                    variant="warning">
                                    Archive
                                  </Button>
                                </div>
                              </td>
                            </tr>
                            )
                        })}
                      </tbody>
                    ) : (
                      <tbody>
                        <div>You currently have no outstanding sign up requests.</div>
                      </tbody>
                    )}
                </Table>
                  </Card.Body> : <Card.Body> Loading... </Card.Body>}
                </Card>
            </Container>
          </Col>
        </Row>
      </div>
    );
  }
  else {
    return(
    <Redirect to="/homepage"/>
    )
  }
}
