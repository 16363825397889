import { createSlice } from '@reduxjs/toolkit';
import API from '../../services/api';
import {BUSINESS_API_URL} from '../../config/service';
import { showError, showSuccess } from '../messages/messagesSlice';

export const updateAffiliationStatusSlice = createSlice({
  name: 'updateaffiliationstatus',
  initialState: {
    value: '',
  },
  reducers: {
    updateAffiliationStatus: (state, action) => {
      state.value = action.payload;
    }
  },
});

export const { updateAffiliationStatus } = updateAffiliationStatusSlice.actions;

export const updateStatus = (cso_identifier, status) => dispatch => {
    API.put(`${BUSINESS_API_URL}/v1/business/affiliate/status`, {
        cso_identifier: cso_identifier,
        status: status
      }).then(function(response) {
        dispatch(showSuccess({ "message": `Affiliation status has been updated to: ${status}` }));
        window.location.reload(false);
    }).catch((error) => {
        dispatch(showError({ "message": `Affiliation status could not be updated.` }));
    });
};

export const selectUpdateAffiliationStatus = state => state.updateaffiliationstatus.value;

export default updateAffiliationStatusSlice.reducer;
