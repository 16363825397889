import React, { useState }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { G_RECAPTCHA_CLIENT_ID } from "../../config/extra";
import { Form, Col, Container, Row, Button, Alert, Card } from 'react-bootstrap';
import { selectSendMessage, sendMessageAction, resetMessageForm } from '../sendmessage/sendExternalMessageSlice';

export const ContactExternal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  //const [error, setError] = useState(false);
  //const [isLoggedOut, setIsLoggedOut] = useState(false);
  const {sentExt} = useSelector(selectSendMessage)
  const location = useLocation()
  const toEmail = location.state?.email.trim()

  React.useEffect(() => {
    dispatch(resetMessageForm())
  }, [toEmail, dispatch]);

  function useFormFields(initialState) {
    const [fields, setValues] = useState(initialState);
    return [
      fields,
      function(event) {
        setValues({
          ...fields,
          [event.target.id]: event.target.value
        });
      },
      function(value) {
        setValues({
          ...fields,
          "formRecaptcha": value
        });
      }
    ];
  }

  const [fields, handleFieldChange, handleRecaptcha] = useFormFields({  
     formComment: "",
     formReplyEmail: toEmail,
     formRecaptcha: ""
  });

  return (
    <div>
      <Container className="pt-5">
        <Card>
          <Card.Header style={{backgroundColor: "#1D345C", color: "white"}}><h3>Send Email Request</h3></Card.Header>
          <Card.Body>
          <Card.Text>
            Send email request to an external user to sign up to the platform.
            Sign-up instructions will be included with this email.
            You must include a message in the comment box and complete the ReCAPTCHA verification in order to send the request.
          </Card.Text>
          <Form>
              <Form.Group as={Row} controlId="formReplyEmail">
                <Form.Label column sm="8">
                  External User Email
                </Form.Label>
                <Col sm="10">
                  <Form.Control type="text"
                    defaultValue={toEmail}
                    onChange={handleFieldChange}
                    disabled={true}
                   />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formComment">
                <Form.Label column sm="8">
                  Comment
                </Form.Label>
                <Col sm="10">
                  <Form.Control as="textarea"
                    rows={3}
                    onChange={handleFieldChange}
                    placeholder="You must add a comment here in order to submit this request..."
                    disabled={sentExt}
                    />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formRecaptcha" className={sentExt ? "d-none" : ""}>
                <Col sm="12" className = "mx-auto"  >
                  <div style={{width: "164px"}} className = "mx-auto">
                    <ReCAPTCHA
                      sitekey={G_RECAPTCHA_CLIENT_ID}
                      onChange={handleRecaptcha}
                      size="compact"
                    />
                  </div>
                </Col>
              </Form.Group>


              <Alert show={sentExt === true} variant="success">
                <Alert.Heading>Thanks!</Alert.Heading>
                <p>
                  Your request has been sent.
                </p>
              </Alert>
              <div style={{float: "right"}} className="pt-2 pl-2">
                <Button
                  onClick={()=> history.goBack() }
                  variant="danger" >
                  Back</Button>
              </div>
              <div className="pt-2 pr-2" style={!fields.formComment || ! fields.formRecaptcha ?
                  {
                    float: "right",
                    opacity: "0.25",
                    style: "cursor: pointer"
                  } : 
                  {
                    float: "right"
                  }}>
                <Button
                  className={sentExt ? "d-none" : ""}
                  disabled={!fields.formComment || ! fields.formRecaptcha}
                  onClick={()=>dispatch(sendMessageAction(fields.formComment, fields.formReplyEmail.trim(), fields.formRecaptcha))}
                  variant="info" >
                  Send Message</Button>
              </div>
          </Form>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}
