
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
} from 'react';
import { Auth } from 'aws-amplify';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthenticator } from "@aws-amplify/ui-react";
import { selectAuthState, setAuthState, resetState } from '../features/auth/authSlice';
import { showWarning} from '../features/messages/messagesSlice';
export const SessionTimeout = () => {
  const events = ['click', 'load', 'scroll', 'mousemove'];
  const [second, setSecond] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { route } = useAuthenticator(context => [context.route]);
  async function logoutUser () {
    if (authState === route) {
      dispatch(showWarning({ "message": "You have been logged out due to inactivity." }));
      await Auth.signOut({ global: true });
      await dispatch(setAuthState("unauthenticated"))
      await dispatch(resetState());
    }
  };
  const authState = useSelector(selectAuthState);
  let timeStamp;
  let warningInactiveInterval = useRef();
  let startTimerInterval = useRef();
  // start inactive check
  let timeChecker = () => {
    startTimerInterval.current = setTimeout(() => {
      let storedTimeStamp = sessionStorage.getItem('lastTimeStamp');
      warningInactive(storedTimeStamp);
    }, 60000);
  };
  // warning timer
  let warningInactive = (timeString) => {
    clearTimeout(startTimerInterval.current);
    let warningShown = false;
    warningInactiveInterval.current = setInterval(() => {
      const maxTime = 15;
      const popTime = 1;
      const warningTimeAlert = 12;
      const diff = moment.duration(moment().diff(moment(timeString)));
      const minPast = diff.minutes();
      const leftSecond = 60 - diff.seconds();
      if (minPast === popTime) {
        setSecond(leftSecond);
        setOpen(true);
      }

      if (minPast === warningTimeAlert && !warningShown) {
        dispatch(showWarning({ "message": "You will be logged out in 3 mins due to inactivity." }));
        warningShown = true;
      }
    
      if (minPast === maxTime) {
        clearInterval(warningInactiveInterval.current);
        setOpen(false);
        sessionStorage.removeItem('lastTimeStamp');
        logoutUser();
      }
    }, 1000);
  };
  // reset interval timer
  let resetTimer = useCallback(() => {
    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);
    if (authState === route) {
      timeStamp = moment();
      sessionStorage.setItem('lastTimeStamp', timeStamp);
    } else {
      clearInterval(warningInactiveInterval.current);
      sessionStorage.removeItem('lastTimeStamp');
    }
    timeChecker();
    setOpen(false);
  }, [authState === route]);
  // handle close popup
  const handleClose = () => {
    setOpen(false);
    resetTimer();
  };
  useEffect(() => {
    if (authState === route) {
      events.forEach((event) => {
        window.removeEventListener(event, resetTimer);
        window.addEventListener(event, resetTimer);
      })
      timeChecker();
    };
    return () => {
      clearTimeout(startTimerInterval.current);
    };
  }, [resetTimer, events, timeChecker, authState, route]);
  if (!isOpen) {
    return null;
  }
  // change fragment to modal and handleclose func to close
  return <Fragment />
};
