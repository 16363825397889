import { createSlice } from '@reduxjs/toolkit';
import API from '../../services/api';
import {SURVEYS_API_URL} from '../../config/service';
import { showError } from '../messages/messagesSlice';

export const listTemplatesSlice = createSlice({
  name: 'listtemplates',
  initialState: {
    listtemplates: [],
  },
  reducers: {
    setTemplateList: (state, action) => {
        if (action.payload)
        {
            state.listtemplates = action.payload;
        }
    }
  },
});

export const { setTemplateList } = listTemplatesSlice.actions;

export const listTemplates = (surveyString) => dispatch => {
  const urlOptions = {
    params: {
      Key: surveyString,
      ContentType: ''
    },
    headers: {
      'Content-Type': ''
    }
  };
  
    try {
          API.get(`${SURVEYS_API_URL}/v1/surveys/s3/templates`, urlOptions)
          .then(function(response)
          { 
            dispatch(setTemplateList(response.data.Contents))
          });
    }
    catch(error) {
        dispatch(showError({ "message": `List templates failed. ${error.message}` }));
    };
};

export const selectTemplates = state => state.listtemplates.listtemplates;
export default listTemplatesSlice.reducer;
