import { createSlice } from '@reduxjs/toolkit';
import API from '../../services/api';
import {SURVEYS_API_URL} from '../../config/service';
import { showError } from '../messages/messagesSlice';

export const surveyHistorySlice = createSlice({
  name: 'surveyhistory',
  initialState: {
    surveyhistory: [],
  },
  reducers: {
    setSurveyHistory: (state, action) => {
        if (action.payload)
        {
            state.surveyhistory = action.payload;
        }
    }
  },
});

export const { setSurveyHistory } = surveyHistorySlice.actions;

export const listSurveyHistory = () => dispatch => {
    try {
          API.get(`${SURVEYS_API_URL}/v1/surveys/s3/history`)
          .then(function(response){
            if(response.data.ResultSet)
            {
             dispatch(setSurveyHistory(response.data.ResultSet.Rows))
            }
          });
    }
    catch(error) {
        dispatch(showError({ "message": `List survey history failed. ${error.message}` }));
    };
};

export const selectSurveyHistory = state => state.surveyhistory.surveyhistory;
export default surveyHistorySlice.reducer;
