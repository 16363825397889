import { createSlice } from '@reduxjs/toolkit';
import API from '../../services/api';
import {SURVEYS_API_URL} from '../../config/service';
import { showError } from '../messages/messagesSlice';

const initialState = {
  externalusertasks: []
}

export const externalUserTasksSlice = createSlice({
  name: 'externalusertasks',
  initialState: {
    externalusertasks: [],
  },
  reducers: {
    setExternalUserTasks: (state, action) => {
        if (action.payload)
        {
            state.externalusertasks = action.payload;
            state.isLoadingExternalUserTasks = false;
        }
    },
    resetExternalUserTasks: state => initialState
  },
});

export const { setExternalUserTasks, resetExternalUserTasks } = externalUserTasksSlice.actions;

export const unlistExternalUserTasks = () => dispatch => {dispatch(resetExternalUserTasks())};

export const listExternalUserTasks = (username, cso_identifier) => dispatch => {
    try {
          API.post(`${SURVEYS_API_URL}/v1/surveys/external/allocated/search`, 
          {
            username: username,
            cso_identifier: cso_identifier
          })
          .then((response) => 
          dispatch(setExternalUserTasks(response.data.Items)));
    }
    catch(error) {
        dispatch(showError({ "message": `List tasks failed. ${error.message}` }));
    };
};

export const selectExternalUserTasks = state => state.externalusertasks.externalusertasks;
export default externalUserTasksSlice.reducer;
