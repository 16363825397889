import React from "react";
import { Button, Table } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import API from '../../services/api';
import { SURVEYS_API_URL } from '../../config/service';
import { selectTemplates } from "./listTemplatesSlice";
import { selectUser } from "../auth/authSlice";
import { listTemplates } from '../downloadtemplate/listTemplatesSlice'
import { selectCsoAllSurveys } from "../csoallsurveys/csoAllSurveysSlice";
import { useLocation } from "react-router-dom";
import { showSuccess } from '../messages/messagesSlice';
import { selectAllocatedSurveys } from "../allocatedsurveylist/allocatedSurveyListSlice";

export const TemplateData = () => {

  const templates = useSelector(selectTemplates);
  const user = useSelector(selectUser);
  const location = useLocation();
  const selectedSurvey = location.pathname.split('download/')[1];
  const csoAdminSurveys = useSelector(selectCsoAllSurveys);
  const csoUserSurveys = useSelector(selectAllocatedSurveys);
  const dispatch = useDispatch();
  let cso;
  let surveys;

  if (user){
    const userGroup = user.groups && user.groups.toString();
    if(userGroup.includes("CSO_ADMINS"))
    {
      cso = true
      surveys = csoAdminSurveys
    }
    else if(userGroup.includes("CSO_USERS"))
    {
      cso = true
      surveys = csoUserSurveys
    }
    else
    {
      cso = false
    }
  }

  function urlParams(surveyString) {
    const urlOptions = {
      params: {
        Key: surveyString,
        ContentType: ''
      },
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/octet-stream'
      }
    }

    return urlOptions
  }

  function dateFormat(longDate) {
    if (longDate && longDate !== "" && longDate !== " ") {
      const unixStamp = Date.parse(longDate);
      const newDate = new Date(unixStamp)
      //const readableDate = newDate
      const dateString = newDate.getDate().toString() +
        "/" + (newDate.getMonth() + 1).toString() +
        "/" + newDate.getFullYear().toString()

      return dateString;
    }
    else return null;
  }

  function downloadFile(urlParams, filename) {

    API.get(`${SURVEYS_API_URL}/v1/surveys/s3/download`, urlParams)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  }

  function byKeyPK(arr, key) {
    for (var i = 0, L = arr.length; i < L; i++) {
      if (arr[i].survey_id === key) {
        return arr[i].PK;
      }
    }
  }

  function removeFile(urlParams, filename) {
    API.get(`${SURVEYS_API_URL}/v1/surveys/s3/remove`, urlParams)
      .then((response) => {
        dispatch(showSuccess({ "message": filename + " has been deleted successfully." }))
        dispatch(listTemplates(byKeyPK(surveys, selectedSurvey)));

      })
  }

  return (
    templates && templates.length > 0 ? (
      <Table responsive striped bordered hover size="sm">
        <thead>
          <tr>
            <td><b>File Name</b></td>
            <td><b>Last Modified Date</b></td>
            <td><b>Download File</b></td>
            {cso?<td><b>Remove File</b></td>:""}
          </tr>
        </thead>
        <tbody>
          {templates.map((templateRow, key) => {

            return (
              <tr key={key}>
                <td>{templateRow.Key.substring(templateRow.Key.lastIndexOf("/") + 1)}</td>
                <td>{dateFormat(templateRow.LastModified)}</td>
                <td>
                  <div style={{ textAlign: "center" }}>
                    <Button 
                      variant="secondary" 
                      onClick={() => downloadFile(urlParams(templateRow.Key), templateRow.Key.substring(templateRow.Key.lastIndexOf("/") + 1))}>
                      Download
                    </Button>
                  </div>
                </td>
                { cso ? <td><Button variant="warning" onClick={() => removeFile(urlParams(templateRow.Key), templateRow.Key.substring(templateRow.Key.lastIndexOf("/") + 1))}>Remove</Button></td> : ""}
              </tr>

            )
          })}
        </tbody>
      </Table>
    ) : (
      <div>&nbsp;No templates have been uploaded to accompany this survey.</div>
    )
  );
};

export default TemplateData;
