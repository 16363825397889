import { createSlice } from '@reduxjs/toolkit';
import API from '../../services/api';
import {USERS_API_URL} from '../../config/service';
import { showError, showSuccess, showInfo } from '../messages/messagesSlice';

export const userManagmentSlice = createSlice({
  name: 'registeruser',
  initialState: {
    value: '',
    success: false,
    userFound: false,
    showAlert: false,
    externaluserlist: [],
    externalusersfound: []
  },
  reducers: {
    userRegisterationStatus: (state, action) => {
      state.value = action.payload;
    },
    userRegistered: state => {
      state.success = true;
    },
    externalUserFound: (state, action) => {
      state.userFound = true;
      state.value = action.payload;
      state.externalusersfound = action.payload;
    },
    userNotFoundAlert: (state, action) => {
      state.showAlert = true;
    },
    resetUIMessage: state => {
      state.success = false;
      state.value = ''
      state.userFound = false;
      state.showAlert = false;
    },
    setExternalUserList: (state, action) =>{
      if(action.payload){
        state.externaluserlist = action.payload;
      }
    }
  },
});

export const { userRegisterationStatus, userRegistered, externalUserFound, userNotFoundAlert, resetUIMessage, setExternalUserList } = userManagmentSlice.actions;

export const registerBusinessUser = (email, username) => dispatch => {
    API.post(`${USERS_API_URL}/v1/users`, {
        email: email,
        username: username.trim()
      }).then((response) => {
        dispatch(showSuccess({ "message": "User registered successfully." }));
        dispatch(userRegistered())
    }).catch((error) => {
        dispatch(showError({ "message": `User registration failed. Email or username is invalid or username already exists. Please ensure there are no spaces in the username.` }));
    });
};

export const searchExternalUser = (email) => dispatch => {
  API.post(`${USERS_API_URL}/v1/users/external/search`, {
      email: email
    }).then((response) => {
      if(response.data.length < 1)
      {
        dispatch(showInfo({ "message": "No external user found." }));
        dispatch(userNotFoundAlert())
      }
      else
      {
        dispatch(showSuccess({ "message": "External user found." }));
        dispatch(externalUserFound(response.data))
      }
  }).catch((error) => {
      dispatch(showError({ "message": "Email is invalid." }));
  });
};

export const listExternalUserAllocated = () => dispatch => {
  API.get(`${USERS_API_URL}/v1/users/external/list`)
  .then((response)=>{
    dispatch(setExternalUserList(response.data));
  })
}

export const selectUserStatus = state => state.registeruser.value;
export const selectSuccess = state => state.registeruser.success;
export const selectUserFound = state => state.registeruser.userFound;
export const selectShowAlert = state => state.registeruser.showAlert;
export const selectExternalUserAllocated = state => state.registeruser.externaluserlist;
export const selectExternalUsersFound = state => state.registeruser.externalusersfound;

export default userManagmentSlice.reducer;
