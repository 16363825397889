import React  from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import { selectSurveyHistory } from './surveyHistorySlice' 

export function SurveyHistoryPreview() {

  const surveyHistory = useSelector(selectSurveyHistory);
  let tableData = [{}]

  function dateFormat(longDate)
  {
    if(longDate && longDate != "" && longDate!= " ")
    {
      const unixStamp = Date.parse(longDate);
      const newDate = new Date(unixStamp).toISOString().substring(0, 10)
      const newTime = new Date(unixStamp).toISOString().substring(11, 19)
      const readableDate = newDate + " " + newTime;
      return readableDate;
    }
    else return null;
    
  } 

  if (surveyHistory && surveyHistory.length > 0)
  {
    surveyHistory.slice(1, surveyHistory.length).map((surveyRow, key) => {
    tableData.push({  
      survey_title: surveyRow.Data[0].VarCharValue,  
      no_of_submissions: surveyRow.Data[2].VarCharValue,
      last_accessed: dateFormat(surveyRow.Data[1].VarCharValue),
      last_submission: dateFormat(surveyRow.Data[3].VarCharValue),
      status: surveyRow.Data[6].VarCharValue
      })
    })
  }

  const columns = [{  
    Header: 'Survey Title',  
    accessor: 'survey_title',
    },{
    Header: 'Last Accessed',  
    accessor: 'last_accessed'
    },{  
    Header: 'No. of Submissions',  
    accessor: 'no_of_submissions',
    },{  
    Header: 'Last Submission',  
    accessor: 'last_submission',
    },{
    Header: 'Status',  
    accessor: 'status',
    }
  ]  

  return (
      <ReactTable  
        data={tableData}  
        columns={columns}  
        className='-striped -highlight'
        minRows={0} 
        defaultPageSize = {4}  
        pageSizeOptions = {[2,4,6]}  
      /> 
    )
};

