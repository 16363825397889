import React  from 'react';
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";

export const FileUploads = (props) => {

  let tableData = [{}]

  const uploads = props.uploads

  if (uploads && uploads.length > 0)
  {
    uploads.map((uploadRow, key) => {
    return tableData.push({  
      user: uploadRow.User,
      filename: uploadRow.Filename,
      timestamp: uploadRow.Timestamp
      })
    })
  }

  const columns = [{  
    Header: () => (
      <div
        style={{
          textAlign:"left"
        }}
      ><b>User</b></div>),  
    accessor: 'user',
    },{
    Header: () => (
      <div
        style={{
          textAlign:"left"
        }}
      ><b>Filename</b></div>),  
    accessor: 'filename',
    },{  
    Header: () => (
      <div
        style={{
          textAlign:"left"
        }}
      ><b>Timestamp</b></div>), 
    accessor: 'timestamp',
    }
  ]  

  return (
      <ReactTable  
        data={tableData}  
        columns={columns}  
        className='-striped -highlight'
        minRows={0} 
        defaultPageSize = {4}  
        pageSizeOptions = {[2,4,6,8]}  
      /> 
    )
};