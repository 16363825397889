import { createSlice } from '@reduxjs/toolkit';
import API from '../../services/api';
import {BUSINESS_API_URL} from '../../config/service';
import { showError, showSuccess } from '../messages/messagesSlice';

export const sendSlice = createSlice({
  name: 'sendmessage',
  initialState: {
    message: '',
    isLoading: false,
    error: false,
    sent: false,
    request: {}
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.message;
      state.isLoading = false;
    },
    success: (state, action) => {
      state.isLoading = false;
      state.sent = true;
      state.message = action.message
    },
    registerSentRequest: (state, action) => {
      state.request = {
        ...state.request,
        [action.payload.surveyId] : true
      }
      console.log("Setting request ", JSON.stringify(state.request));
    },
    deleteSentRequest: (state, action) => {
      state.request = {
        ...state.request,
        [action.payload.surveyId] : false
      }
      console.log("Setting request ", JSON.stringify(state.request));
    }
  },
});

export const { startLoading, hasError, success, registerSentRequest, deleteSentRequest} = sendSlice.actions;

export const selectSendMessage = state => state.sendmessage;

export const sendMessageAction = (comment, replyEmail, reCaptcha) => dispatch => {
  dispatch(startLoading());
  API.post(`${BUSINESS_API_URL}/v1/business/contacts`, {
      comment,
      replyEmail,
      reCaptcha
    }).then((response) => {
      dispatch(showSuccess({ "message": "Message was sent successfully." }));
      dispatch(success({message: comment}))
  }).catch((error) => {
      dispatch(showError({ "message": `We ran into an issue sending your message. ${error.message}` }));
      dispatch(hasError({message: error.message}))
  });
};

export const requestSubmission = (surveyId) => dispatch => {
  dispatch(registerSentRequest({surveyId, loading: true}))
  API.post(`${BUSINESS_API_URL}/v1/business/submission`, {
      surveyId
    }).then((response) => {
      dispatch(registerSentRequest({surveyId}))
      dispatch(showSuccess({ "message": `Your request for a copy of the survey ${surveyId} was sent to CSO.` }));
  }).catch((error) => {
    console.log("Status... ", error.response.status, error.response.status === 400);
    if (error.response.status === 400) dispatch(registerSentRequest({surveyId}))
    else dispatch(deleteSentRequest({surveyId}))
    return dispatch(showError({ "message": `${error.response.data.message}` }));
  });
};

export default sendSlice.reducer;
