import React, { useMemo } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Container, Card, Button, Col, OverlayTrigger, Popover } from 'react-bootstrap';
import { CardBody } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle, faSort, faInfoCircle, faEyeSlash, faFileAlt, faEye } from '@fortawesome/free-solid-svg-icons'
import "bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../allocatedsurveydata/TableContainer"
import { useSelector } from "react-redux";
import { selectExternalUserTasks } from './externalUserTasksSlice';
import { FileUploads } from "../allocatedsurveydata/FileUploads";

export const ExternalUserTasks = () => {

  const allsurveys = useSelector(selectExternalUserTasks);
  const location = useLocation()
  const history = useHistory()
  const org_id = location.state?.cso_id

  const company_name = location.state?.company_name
  let allSurveys = []

  function byKeyStartDate(arr, key) {
    const keySK = key.SK
    for (var i = 0, L = arr.length; i < L; i++) {
      if (arr[i].SK === keySK) {
        if (arr[i].PINStartDate) {
          return arr[i].PINStartDate
        }
        else {
          return key.survey_start_date
        }
      }
    }
  }

  function byKeyEndDate(arr, key) {
    const keySK = key.SK
    for (var i = 0, L = arr.length; i < L; i++) {
      if (arr[i].SK === keySK) {
        if (arr[i].PINEndDate) {
          return arr[i].PINEndDate
        }
        else {
          return key.survey_end_date
        }
      }
    }
  }

  function dateFormat(longDate) {
    if (longDate && longDate !== "" && longDate !== " ") {
      const unixStamp = Date.parse(longDate);
      const newDate = new Date(unixStamp)
      //const readableDate = newDate
      const dateString = newDate.getDate().toString() +
        "/" + (newDate.getMonth() + 1).toString() +
        "/" + newDate.getFullYear().toString()

      return dateString;
    }
    else return null;
  }

  const renderRowSubComponent = row => {

    const uploads = allsurveys.filter(obj => {
      return (obj.SK === row.original.id) && obj.fileUploads && obj.fileUploads.length > 0
    })

    return (
      uploads.length === 0 ? (
        <div style={{ margin: "0 auto", textAlign: "center" }}> No file upload data available </div>
      ) : (
        <Card>
          <CardBody>
            <FileUploads uploads={uploads[0].fileUploads} />
          </CardBody>
        </Card>
      )
    )
  }

  if (allsurveys && allsurveys.length > 0) {
    allsurveys.map((surveyRow, key) => {

      return allSurveys.push({
        id: surveyRow.PK,
        title: surveyRow.author_title,
        start_date: dateFormat(byKeyStartDate(allsurveys, surveyRow)),
        end_date: dateFormat(byKeyEndDate(allsurveys, surveyRow)),
        download: surveyRow.survey_id,
        upload: surveyRow.survey_id
      })
    })
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Survey Title',
        accessor: 'title',
        disableFilters: true,
        disableSortBy: true
      }, 
      {
        disableFilters: true,
        disableSortBy: false,
        Header: () => (
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={(
              <Popover id="popover-basic">
                <Popover.Content>
                  Sort surveys by start date
                </Popover.Content>
              </Popover>
            )} >
            <div>
              Start Date <FontAwesomeIcon icon={faSort} />
            </div>
          </OverlayTrigger>
        ),
        sortable: true,
        resizable: false,
        accessor: 'start_date',
        Cell: props => {
          return (
            <div style={{ textAlign: "left" }} >
              {props.value}
            </div>
          )
        }
      }, {
        disableFilters: true,
        disableSortBy: false,
        Header: () => (
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={(
              <Popover id="popover-basic">
                <Popover.Content>
                  Sort surveys by end date
                </Popover.Content>
              </Popover>
            )} >
            <div>
              End Date <FontAwesomeIcon icon={faSort} />
            </div>
          </OverlayTrigger>),
        sortable: true,
        resizable: false,
        accessor: 'end_date',
        Cell: props => {
          return (
            <div style={{ textAlign: "left" }} >
              {props.value}
            </div>
          )
        }
      },
      {
        disableFilters: true,
        disableSortBy: true,
        Header: () => (
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={(
              <Popover id="popover-basic">
                <Popover.Content>
                  Use this button to download templates required respond to the request.
                </Popover.Content>
              </Popover>
            )} >
            <div>
              Download Template <FontAwesomeIcon icon={faQuestionCircle} />
            </div>
          </OverlayTrigger>
        ),
        sortable: false,
        resizable: false,
        accessor: 'download',
        Cell: props => {
          return (
            <div style={{ textAlign: "center" }} >
              <Link to={`/home/download/${props.value}`} >
                <Button variant="warning">Download Template</Button>
              </Link>
            </div>
          )
        }
      },
    {
        disableFilters: true,
        disableSortBy: true,
        Header: () => (
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={(
              <Popover id="popover-basic">
                <Popover.Content>
                  Use this button to upload files.
                </Popover.Content>
              </Popover>
            )} >
            <div>
              Upload Files <FontAwesomeIcon icon={faQuestionCircle} />
            </div>
          </OverlayTrigger>),
        sortable: false,
        resizable: false,
        accessor: 'upload',
        Cell: props => {
          return (
            <div style={{ textAlign: "center" }} >
              <Link to={{pathname: `/home/upload/${props.value}`, state: {org_id: org_id} }}>
                <Button variant="secondary">Upload File</Button>
              </Link>
            </div>
          )
        }
      }, 
      {
        Header: () => (
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={(
              <Popover id="popover-basic">
                <Popover.Content>
                  View details of previously uploaded files.
                </Popover.Content>
              </Popover>
            )} >
            <div style={{ textAlign: "center" }}>
              <FontAwesomeIcon icon={faFileAlt} />
            </div>
          </OverlayTrigger>),
        id: 'expander', // 'id' is required
        Cell: ({ row }) => (
          <div style={{ textAlign: "center" }} >
            <span {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? <FontAwesomeIcon icon={faEyeSlash} title="Toggle Row Expanded" /> : <FontAwesomeIcon icon={faEye} title="Toggle Row Hidden" />}
            </span>
          </div>
        )
      }
    ],
    [org_id]
  )

  return (
    <Col style={{ paddingLeft: 50, paddingRight: 50 }}>
      <Container fluid className='pt-5'>
        <Card>
          <Card.Header style={{ backgroundColor: "#1D345C", color: "white" }}>
            <h3 style={{ textAlign: 'left', float: 'left' }}>Available Tasks: <b>{company_name}; {org_id}</b>&nbsp;</h3>
            <OverlayTrigger
              placement="top"
              delay={{ show: 150, hide: 300 }}
              overlay={(
                <Popover id="popover-basic">
                  <Popover.Content>
                    Once a file has been uploaded it may take a few minutes for the tables to update.
                  </Popover.Content>
                </Popover>
              )} >
              <h5 style={{ textAlign: 'right', float: 'right' }}><FontAwesomeIcon icon={faInfoCircle} /></h5>
            </OverlayTrigger>
          </Card.Header>
          <Card.Body>
            <TableContainer columns={columns} data={allSurveys} renderRowSubComponent={renderRowSubComponent} />
          </Card.Body>
        </Card>
          <div style={{float: "right"}} className="pt-2 pr-2">
            <Button
              onClick={() => history.goBack()}
              variant='info'
              >Back to Home
            </Button>
          </div>
      </Container>
    </Col>
  );
};

export default ExternalUserTasks;
