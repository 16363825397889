import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Container, Card, Button, Col, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle, faSort } from '@fortawesome/free-solid-svg-icons'
import "bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../allocatedsurveydata/TableContainer"
import { useSelector, useDispatch } from "react-redux";
import { selectCsoAllSurveys, csoAllSurveys } from "../csoallsurveys/csoAllSurveysSlice";

export const SurveyManagementCso = () => {

  const dispatch = useDispatch();
  let surveys = useSelector(selectCsoAllSurveys); 
  let allSurveys = []

  React.useEffect(() => {
    dispatch(csoAllSurveys())
  }, [dispatch]);

  function byKeyStartDate(arr, key) {
    const keySK = key.SK
    for (var i = 0, L = arr.length; i < L; i++) {
      if (arr[i].SK === keySK) {
        if (arr[i].PINStartDate) {
          return arr[i].PINStartDate
        }
        else {
          return key.survey_start_date
        }
      }
    }
  }

  function byKeyEndDate(arr, key) {
    const keySK = key.SK
    for (var i = 0, L = arr.length; i < L; i++) {
      if (arr[i].SK === keySK) {
        if (arr[i].PINEndDate) {
          return arr[i].PINEndDate
        }
        else {
          return key.survey_end_date
        }
      }
    }
  }

  function dateFormat(longDate) {
    if (longDate && longDate !== "" && longDate !== " ") {
      const unixStamp = Date.parse(longDate);
      const newDate = new Date(unixStamp)
      //const readableDate = newDate
      const dateString = newDate.getDate().toString() +
        "/" + (newDate.getMonth() + 1).toString() +
        "/" + newDate.getFullYear().toString()

      return dateString;
    }
    else return null;
  }

  if (surveys && surveys.length > 0) {
    surveys.map((surveyRow, key) => {

      return allSurveys.push({
        id: surveyRow.author_title,
        start_date: dateFormat(byKeyStartDate(surveys, surveyRow)),
        end_date: dateFormat(byKeyEndDate(surveys, surveyRow)),
        manage_users: surveyRow.survey_id
      })
    })
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Survey Title',
        accessor: 'id',
        disableFilters: false,
        disableSortBy: false
      }, {
        disableFilters: true,
        disableSortBy: false,
        Header: () => (
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={(
              <Popover id="popover-basic">
                <Popover.Content>
                  Sort surveys by start date
                </Popover.Content>
              </Popover>
            )} >
            <div>
              Start Date <FontAwesomeIcon icon={faSort} />
            </div>
          </OverlayTrigger>
        ),
        sortable: true,
        resizable: false,
        accessor: 'start_date',
        Cell: props => {
          return (
            <div style={{ textAlign: "left" }} >
              {props.value}
            </div>
          )
        }
      }, {
        disableFilters: true,
        disableSortBy: false,
        Header: () => (
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={(
              <Popover id="popover-basic">
                <Popover.Content>
                  Sort surveys by end date
                </Popover.Content>
              </Popover>
            )} >
            <div>
              End Date <FontAwesomeIcon icon={faSort} />
            </div>
          </OverlayTrigger>),
        sortable: true,
        resizable: false,
        accessor: 'end_date',
        Cell: props => {
          return (
            <div style={{ textAlign: "left" }} >
              {props.value}
            </div>
          )
        }
      },
      {
        disableFilters: true,
        disableSortBy: true,
        Header: () => (
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={(
              <Popover id="popover-basic">
                <Popover.Content>
                  Use this button manage survey users.
                </Popover.Content>
              </Popover>
            )} >
            <div>
              Manage Users <FontAwesomeIcon icon={faQuestionCircle} />
            </div>
          </OverlayTrigger>
        ),
        sortable: false,
        resizable: false,
        accessor: 'manage_users',
        Cell: props => {
          return (
            <div style={{ textAlign: "center" }} >
              <Link to ={`/home/users/manage/${props.value}`} >
                    <Button variant="secondary">Manage Users</Button>
              </Link>
            </div>
          )
        }
      }
    ],
    []
  )

  return (
    <Col style={{ paddingLeft: 50, paddingRight: 50 }}>
      <Container fluid className='pt-5'>
        <Card>
          <Card.Header style={{ backgroundColor: "#45C1C0", color: "white" }}>
            <h3 style={{ textAlign: 'left', float: 'left' }}>All Surveys &nbsp;</h3>
          </Card.Header>
          <Card.Body>
          {surveys && surveys.length > 0 ? (
            <TableContainer columns={columns} data={allSurveys} />)
            : (
                <div>&nbsp;Loading all surveys...</div>)}
          </Card.Body>
        </Card>
      </Container>
    </Col>
  );
};

export default SurveyManagementCso;
