import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useSelector, useDispatch } from 'react-redux';
import { closeAlert, confirmAlert, selectCurrentAlertModal } from './alertSlice';
import AlertStyles from './Alert.module.scss';

export function Alert() {

    const dispatch = useDispatch();
    const currentAlert = useSelector(selectCurrentAlertModal);

    const getClassName = ( component, type ) => {
        if(component === "header") {
            if (type === 'error') {
                return AlertStyles.errorAlertHeader;
            } else if (type === 'info'){
                return AlertStyles.infoAlertHeader;
            } else if (type === 'warning') {
                return AlertStyles.warningAlertHeader;
            } else {
                return AlertStyles.successAlertHeader;
            }
        }
        if(component === "footer" || component === "body") {
            if (type === 'error') {
                return AlertStyles.errorAlertBody;
            } else if (type === 'info'){
                return AlertStyles.infoAlertBody;
            } else if (type === 'warning') {
                return AlertStyles.warningAlertBody;
            } else {
                return AlertStyles.successAlertBody;
            }
        }
    };

    const confirmButtonClick = (actionId) => {
        if(actionId) {
            dispatch({type: actionId});
            dispatch(confirmAlert());
        } else {
            dispatch(confirmAlert());
        }
    }
  
    return (
        <div>
            <Modal show={currentAlert.visible} onHide={() => dispatch(closeAlert())} autoFocus backdrop={true} centered keyboard={true}>
                <Modal.Header closeButton className={getClassName('header', currentAlert.type)}>
                    <Modal.Title>{currentAlert.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={getClassName('body', currentAlert.type)}>{currentAlert.message}</Modal.Body>
                <Modal.Footer className={getClassName('footer', currentAlert.type)}>
                    <Button variant="secondary" onClick={() => dispatch(closeAlert())}>
                        {currentAlert.closeButtonText}
                    </Button>
                    <Button variant="primary" onClick={() => confirmButtonClick(currentAlert.callBackActionId)}>
                        {currentAlert.confirmButtonText}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
