import { configureStore, combineReducers } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import cookiereducer from '../features/cookiemodal/cookieSlice';
import authReducer from '../features/auth/authSlice';
import messagesReduce from '../features/messages/messagesSlice';
import sendMessageReducer from '../features/sendmessage/sendMessageSlice';
import sendExtMessageReducer from '../features/sendmessage/sendExternalMessageSlice';
import alertReduce from '../features/alert/alertSlice';
import removeAccountSlice from '../features/removeaccount/removeAccountSlice';
import userManagmentSlice from '../features/usermanagement/userManagementSlice';
import makeAdminSlice from '../features/makeadmin/makeAdminSlice';
import revokeAdminSlice from '../features/revokeadmin/revokeAdminSlice';
import usersListSlice from '../features/userlist/usersListSlice';
import userGroupsSlice from '../features/usergroups/userGroupsSlice';
import surveyUsersSlice from '../features/managesurveyusers/ManageSurveyUsersSlice';
import usersSurveyDataSlice from '../features/manageuserdetails/ManageUsersSurveyDataSlice';
import removeUserSlice from '../features/removeuser/removeUserSlice';
import surveyListSlice from '../features/surveylist/surveyListSlice';
import surveyHistorySlice from '../features/surveyhistory/surveyHistorySlice';
import surveySubmissionsSlice from '../features/surveyhistory/surveySubmissionsSlice';
import surveyDataSlice from '../features/surveydata/surveyDataSlice';
import allocatedSurveyDataSlice from '../features/allocatedsurveydata/allocatedSurveyDataSlice'
import allocateSurveySlice from '../features/allocatesurvey/allocateSurveySlice';
import allocatedSurveyListSlice from '../features/allocatedsurveylist/allocatedSurveyListSlice';
import affiliatedOrgsListSlice from '../features/affiliatedorgslist/affiliatedOrgsListSlice';
import csoAllSurveysSlice from '../features/csoallsurveys/csoAllSurveysSlice';
import csoAllUsersSlice from '../features/csoallusers/csoAllUsersSlice';
import csoAllBusinessSlice from '../features/csoallbusiness/csoAllBusinessSlice';
import eqPortalAccessSlice from '../features/eqportalaccess/eqPortalAccessSlice';
import listTemplatesSlice from '../features/downloadtemplate/listTemplatesSlice';
import userAffiliationSlice from '../features/useraffiliation/userAffiliationSlice';
import updateAffiliationStatusSlice from '../features/affiliatedorgslist/updateAffiliationStatusSlice';
import allocateSurveyExternalSlice from '../features/allocatesurvey/allocateSurveyExternalSlice';
import externalUserTasksSlice from '../features/externalsurveyallocation/externalUserTasksSlice';
import revokeAffiliationSlice from '../features/revokeaffiliation/revokeAffiliationSlice';
import externalSignUpRequestsSlice from '../features/externalsignuprequests/externalSignUpRequestsSlice';

const combinedReducer = combineReducers({
    counter: counterReducer,
    cookies: cookiereducer,
    auth: authReducer,
    messages: messagesReduce,
    sendmessage: sendMessageReducer,
    sendmessageext: sendExtMessageReducer,
    alert: alertReduce,
    removeaccount: removeAccountSlice,
    registeruser: userManagmentSlice,
    makeadmin: makeAdminSlice,
    revokeadmin: revokeAdminSlice,
    userslist: usersListSlice,
    groups: userGroupsSlice,
    removeuser: removeUserSlice,
    surveylist: surveyListSlice,
    surveyhistory: surveyHistorySlice,
    surveysubmissions: surveySubmissionsSlice,
    surveyusers: surveyUsersSlice,
    userssurveydata: usersSurveyDataSlice,
    surveydata: surveyDataSlice,
    allocatedsurveydata: allocatedSurveyDataSlice,
    allocatesurvey: allocateSurveySlice,
    allocatedsurveylist: allocatedSurveyListSlice,
    affiliatedorgslist: affiliatedOrgsListSlice,
    csoallsurveys: csoAllSurveysSlice,
    csoallbusiness: csoAllBusinessSlice,
    csoallusers: csoAllUsersSlice,
    eqportalaccess: eqPortalAccessSlice,
    listtemplates: listTemplatesSlice,
    affiliateuser: userAffiliationSlice,
    updateaffiliationstatus: updateAffiliationStatusSlice,
    allocatesurveyexternal: allocateSurveyExternalSlice,
    externalusertasks: externalUserTasksSlice,
    revokeaffiliation: revokeAffiliationSlice,
    externalsignuprequests: externalSignUpRequestsSlice
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/resetState') { // check for action type 
    window.location.reload(false);
    return combinedReducer(undefined, action)
  }
  return combinedReducer(state, action);
};


export default configureStore({
  reducer: rootReducer
});
