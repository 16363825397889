import React from "react";
import { Link } from 'react-router-dom';
import { Button, Table } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { selectSurveys } from "../surveylist/surveyListSlice";

export const SurveyData = () => {

  const surveys = useSelector(selectSurveys);

  function dateFormat(longDate) {
    if (longDate && longDate !== "" && longDate !== " ") {
      const unixStamp = Date.parse(longDate);
      const newDate = new Date(unixStamp)
      //const readableDate = newDate
      const dateString = newDate.getDate().toString() +
        "/" + (newDate.getMonth() + 1).toString() +
        "/" + newDate.getFullYear().toString()

      return dateString;
    }
    else return null;
  }

  function byKeyStartDate(arr, key) {
    const keySK = key.SK
    for (var i = 0, L = arr.length; i < L; i++) {
      if (arr[i].SK === keySK) {
        if (arr[i].PINStartDate) {
          return arr[i].PINStartDate
        }
        else {
          return key.survey_start_date
        }
      }
    }
  }

  function byKeyEndDate(arr, key) {
    const keySK = key.SK
    for (var i = 0, L = arr.length; i < L; i++) {
      if (arr[i].SK === keySK) {
        if (arr[i].PINEndDate) {
          return arr[i].PINEndDate
        }
        else {
          return key.survey_end_date
        }
      }
    }
  }

  return (
    surveys && surveys.length > 0 ? (
      <Table responsive striped bordered hover size="sm">
          <thead>
         
            <tr>
              <td><b>Survey Title</b></td>
              <td><b>Survey Start Date</b></td>
              <td><b>Survey End Date</b></td>
              <td><b>Manage Users Allocation</b></td>
            </tr>
          </thead>
        {surveys ? (
          <tbody>
            {surveys.map((surveyRow, key) => {

              return (
                  <tr key={key}>
                    <td>{surveyRow.author_title}</td>
                    <td>{dateFormat(byKeyStartDate(surveys, surveyRow))}</td> 
                    <td>{dateFormat(byKeyEndDate(surveys, surveyRow))}</td> 
                    <td>
                      <div style={{ textAlign: "center" }}>
                        <Link to ={`/home/users/manage/${surveyRow.survey_id}`} >
                            <Button variant="secondary">Manage Users</Button>
                        </Link>
                      </div>
                    </td>
                  </tr>

              )
            })}
          </tbody>
        ) : (
          <div>Loading...</div>
        )}
      </Table>
    ) : (
      <div>&nbsp;No surveys have been allocated to your company.</div>
    )
  );
};

export default SurveyData;
