import { createSlice } from '@reduxjs/toolkit';

export const messageDelayHideTime = 12000;

export const messagesSlice = createSlice({
  name: 'messages',
  initialState: {
    value: []
  },
  reducers: {
    removeError: (state, action) => {
        state.value = state.value.filter( (message) => {
            return message.key !== action.payload.key;
        });
    },
    clearErrors: state => {
      state.value = [];
    },
    showMessage: (state, action) => {
        if(action.payload) {
            const message = {
                type: action.payload.type || 'info',
                header: action.payload.header || '',
                sideheader: action.payload.sideheader || '',
                body: action.payload.message || '',
                key: Date.now(),
            };
            state.value.push(message);
        }
    },
    showError: (state, action) => {
        if(action.payload) {
            const message = {
                type: 'error',
                header: 'ERROR',
                sideheader: '',
                body: action.payload.message || '',
                key: Date.now()
            };
            state.value.push(message);
        }
    },
    showSuccess: (state, action) => {
        if(action.payload) {
            const message = {
                type: 'success',
                header: 'SUCCESS',
                sideheader: '',
                body: action.payload.message || '',
                key: Date.now()
            };
            state.value.push(message);
        }
    },
    showWarning: (state, action) => {
        if(action.payload) {
            const message = {
                type: 'warning',
                header: 'WARNING',
                sideheader: '',
                body: action.payload.message || '',
                key: Date.now()
            };
            state.value.push(message);
        }
    },
    showInfo: (state, action) => {
        if(action.payload) {
            const message = {
                type: 'info',
                header: 'INFO',
                sideheader: '',
                body: action.payload.message || '',
                key: Date.now()
            };
            state.value.push(message);
        }
    }
  },
});

export const { clearErrors, showInfo, showWarning, showSuccess, showError, showMessage, removeError } = messagesSlice.actions;

export const selectMessages = state => state.messages.value;

export default messagesSlice.reducer;
