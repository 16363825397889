import { createSlice } from '@reduxjs/toolkit';
import API from '../../services/api';
import {USERS_API_URL} from '../../config/service';
import { showError, showSuccess } from '../messages/messagesSlice';

export const removeUserSlice = createSlice({
  name: 'removeuser',
  initialState: {
    value: '',
  },
  reducers: {
    userRemovalStatus: (state, action) => {
      state.value = action.payload;
    }
  },
});

export const { userRegisterationStatus } = removeUserSlice.actions;

export const removeBusinessUser = username => dispatch => {
    API.delete(`${USERS_API_URL}/v1/users`, {
        username: username,
      }).then((response) => {
        dispatch(showSuccess({ "message": "User removed successfully." }));
    }).catch((error) => {
        dispatch(showError({ "message": `User removal failed. ${error.message}` }));
    });
};

export const selectUserRemovalStatusSelector = state => state.removeuser.value;

export default removeUserSlice.reducer;
