import React from 'react';
import { Container } from 'react-bootstrap';
import styles from './Footer.module.scss';

export function FooterPadding() {

    return (
        <Container className={styles.containerElement}>

        </Container>

     )

}