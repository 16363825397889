import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { NavLink, Link} from "react-router-dom";
import { selectAuthState, selectUser } from '../auth/authSlice';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom'
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Signout } from '../signout/Signout';
import { selectEqPortalAccess } from '../eqportalaccess/eqPortalAccessSlice';
import {Navigation }from "../../config/navigationConfig";

export function NavigationBar() {
  const user = useSelector(selectUser);
  const authState = useSelector(selectAuthState);
  const location = useLocation();
  const eqportalaccess = useSelector(selectEqPortalAccess);
  const { route } = useAuthenticator(context => [context.route]);
  const currentpath = location.pathname;

  let fluid = false;
  let brandText = 'CSO Questionnaire Portal';
  const isUserSignedIn = (authState === route && user ) ? true : false;

  const isAdminUser = (props) => {
    const userType = props.user.groups;
    if(userType && (userType.includes('BUSINESS_ADMINS') || userType.includes('CSO_ADMINS'))){
      return true;
    }else{
      return false;
    }
  }
  const isCsoUser = (props) => {
    const userType = props.user.groups;
    if(userType && (userType.includes('CSO_USERS') || userType.includes('CSO_ADMINS'))){
      return true;
    }else{
      return false;
    }
  }

  const isExternalUser = (props) => {
    const userType = props.user.groups;
    if(userType && (userType.includes('EXTERNAL_USERS'))){
      return true;
    }else{
      return false;
    }
  }

  if(currentpath.includes('/terms')) {
    brandText = "CSO Questionnaire Portal Terms And Conditions"
  } else if (currentpath.includes('/home')) {
    brandText = "Home"
    fluid = true;
  }
    else if (currentpath.includes('/changepwd')) {
      brandText = "Change Password"
      fluid = true;
  }

  const SignedInUserInfo = (props) => {
    let signedInAs;
    const userType = props.user.groups;
    if(userType && (userType.includes('BUSINESS_ADMINS') || userType.includes('CSO_ADMINS'))) {
      signedInAs = 'Signed in as administrator: ';
    }
    else if(isExternalUser({user})){
      signedInAs = 'Signed in as external user: ';
    }
    else if(userType){
      signedInAs = 'Signed in as user: ';
    }
    return (
      <Navbar.Collapse className="justify-content-end">
        <Navbar.Text>
          {signedInAs} <Link to="/home/profile">{props.user.username}</Link>&nbsp;
        </Navbar.Text>
        &nbsp;<Signout/>
      </Navbar.Collapse>
    )
  };

  const Contacts = () => {
      return (
        <div>
          <NavLink  isActive={(match, location) => { return PathToHighlight(location, "Contact") }} to="/home/contacts" activeStyle={{textDecoration:"underline", fontWeight:"600"}} style={{ color: '#FFF' }}>Contact Us</NavLink>
        </div>
      );
    }

  const AdminContentUserManagement = () => {
    return (
      <div>
        <NavLink isActive={(match, location) => { return PathToHighlight(location, "User_Management") }} to="/home/users" activeStyle={{textDecoration:"underline", fontWeight:"600"}} style={{ color: '#FFF' }}>User Management</NavLink>
      </div>
    );
  }

  const AdminContentExternalUserManagement = () => {
    return (
      <div>
        <NavLink isActive={(match, location) => { return PathToHighlight(location, "External_User_Management") }} to="/home/external" activeStyle={{textDecoration:"underline", fontWeight:"600"}} style={{ color: '#FFF' }}>External User Management</NavLink>
      </div>
    );
  }

  const AdminContentBusinessInfo = () => {
    return (
      <div>
        <NavLink to="/home/surveys" isActive={(match, location) => { return PathToHighlight(location, "Survey_Management") }} activeStyle={{textDecoration:"underline", fontWeight:"600"}} style={{ color: '#FFF' }}>Survey Management</NavLink>
      </div>
    );
  }

  const CsoAdminContentUserInfo = () => {
    return (
      <div>
        <NavLink to="/home/userlist" isActive={(match, location) => { return PathToHighlight(location, "All_Users") }} activeStyle={{textDecoration:"underline", fontWeight:"600"}} style={{ color: '#FFF' }}>All Users</NavLink>
      </div>
    );
  }

  // const EQSurveyHistory = () => {
  //   return (
  //     <div>
  //       <Link exact activeStyle={{textDecoration:"underline", fontWeight:"600"}} to="/home/history" style={{ color: '#FFF' }}>Survey History</Link>
  //     </div>
  //   );
  // }

  const UserHome = () => {
    if(eqportalaccess)
    {
      return (
        <div>
          <NavLink to="/home" exact isActive={(match, location) => { return PathToHighlight(location, "Home") }} activeStyle={{textDecoration:"underline", fontWeight:"600"}} style={{ color: '#FFF' }}>{brandText}</NavLink>
        </div>
      );
    }
    else
    {
      return (
        <div>
          <NavLink to="/home" exact isActive={(match, location) => { return PathToHighlight(location, "Home") }} activeStyle={{textDecoration:"underline", fontWeight:"600"}} style={{ color: '#FFF' }}>Secure Deposit Box</NavLink>
        </div>
      );
    }
  }

  const PathToHighlight=(path, component) =>{
    const id = path.pathname;
    return Navigation[component].includes(path.pathname) || Navigation[component].includes(path.pathname.slice(0 , id.lastIndexOf('/'))) ;
  }

  return (
    <Container fluid={fluid} className='pt-4 pr-0 pl-0'>
      <Navbar bg="csonav" variant="dark">
          {isUserSignedIn && <Navbar.Brand> <UserHome user={user} /> </Navbar.Brand>}
          {isUserSignedIn && <Navbar.Brand><NavLink isActive={(match, location) => { return PathToHighlight(location, "User_Profile") }} to="/home/profile" activeStyle={{textDecoration:"underline", fontWeight:"600"}} style={{ color: '#FFF' }}>User Profile</NavLink></Navbar.Brand>}
          {isUserSignedIn && eqportalaccess && !isExternalUser({user}) && <Navbar.Brand> <NavLink to="/home/history" exact activeStyle={{textDecoration:"underline", fontWeight:"600"}} style={{ color: '#FFF' }}>Survey History</NavLink></Navbar.Brand>}
          {isUserSignedIn && isAdminUser({user}) && isCsoUser({user}) && <Navbar.Brand><CsoAdminContentUserInfo/></Navbar.Brand>}
          {isUserSignedIn && isAdminUser({user}) && <Navbar.Brand><AdminContentUserManagement/></Navbar.Brand>}
          {isUserSignedIn && isAdminUser({user}) && !isCsoUser({user}) && <Navbar.Brand><AdminContentExternalUserManagement/></Navbar.Brand>}
          {isUserSignedIn && isAdminUser({user}) && <Navbar.Brand><AdminContentBusinessInfo/></Navbar.Brand>}
          {isUserSignedIn && !isCsoUser({user}) && <Navbar.Brand><Contacts/></Navbar.Brand>}
        <Navbar.Toggle />
        {isUserSignedIn && <SignedInUserInfo user={user}/>}
        <Nav className="mr-auto">
          <Nav.Link href="#home"></Nav.Link>
        </Nav>
      </Navbar>

    </Container>
  );
};
