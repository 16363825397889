import { createSlice } from '@reduxjs/toolkit';
import API from '../../services/api';
import {SURVEYS_API_URL} from '../../config/service';
import { showError } from '../messages/messagesSlice';

export const surveySubmissionsSlice = createSlice({
  name: 'surveysubmissions',
  initialState: {
    surveysubmissions: [],
  },
  reducers: {
    setSurveySubmissions: (state, action) => {
        if (action.payload)
        {
            state.surveysubmissions = action.payload;
        }
    }
  },
});

export const { setSurveySubmissions } = surveySubmissionsSlice.actions;

export const listSurveySubmissions = () => dispatch => {
    try {
          API.get(`${SURVEYS_API_URL}/v1/surveys/s3/submissions`)
          .then(function(response){
            if(response.data.ResultSet)
            {
             dispatch(setSurveySubmissions(response.data.ResultSet.Rows))
            }
          });
    }
    catch(error) {
        dispatch(showError({ "message": `List survey submissions failed. ${error.message}` }));
    };
};

export const selectSurveySubmissions = state => state.surveysubmissions.surveysubmissions;
export default surveySubmissionsSlice.reducer;
