const USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID;
const REGION = process.env.REACT_APP_REGION;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const DOMAIN = process.env.REACT_APP_DOMAIN;
const SECURE = process.env.REACT_APP_SECURE;


const authConfig = {
    Auth: {

        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        // identityPoolId: POOL_ID,

        // REQUIRED - Amazon Cognito Region
        region: REGION,

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
        // Required only if it's different from Amazon Cognito Region
        // identityPoolRegion: 'XX-XXXX-X',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: USER_POOL_ID,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: CLIENT_ID,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,

        // OPTIONAL - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        cookieStorage: {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
            domain: DOMAIN,
        // OPTIONAL - Cookie path
            path: '/',
        // OPTIONAL - Cookie expiration in days
            expires: 365,
        // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
            sameSite: "strict" | "lax",
        // OPTIONAL - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
            secure: SECURE,

            httpOnly: true
        },

        // OPTIONAL - customized storage object
        storage: window.localStorage,

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType: 'USER_SRP_AUTH'

    }
}

export const getAuthConfig = ({ useCookies = true } = {}) => {
    let conf = Object.assign({}, authConfig)
    if(!useCookies) {
        delete conf['Auth']['cookieStorage'];
    }
    return conf;
};