import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Col, Container, Row, Button, Alert, OverlayTrigger, Popover } from 'react-bootstrap';
import {
  Redirect
} from "react-router-dom";
import Card from 'react-bootstrap/Card';
import { selectUser } from '../auth/authSlice';
import {registerBusinessUser, resetUIMessage, selectSuccess} from './userManagementSlice';
import {UsersList} from '../userlist/usersList';
import {useDispatch} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export function UserManagement() {

  const user = useSelector(selectUser);
  const success = useSelector(selectSuccess)
  const [usernameState, setUsernameState] = useState('')
  const [emailState, setEmailState] = useState('')

  const getUsername = (e) => {
    const usernameValue = e.target.value;
    setUsernameState(usernameValue)
  };

  const getEmail = (e) => {
    const emailValue = e.target.value;
    setEmailState(emailValue)
  };

  const userType= user.groups && user.groups.toString();
  let admin;
  if(typeof userType !== "undefined")
  {
    const strIndex = userType.indexOf('ADMINS');
    if(strIndex === -1) {
      admin = false;
    } else {
      admin = true;
    }
  } 

  const dispatch = useDispatch();
  
  if(admin){
    return (
      <div style={{padding:"3rem 2rem 0 2rem"}}>
        <Row>
          <Col>
            <Container>
              <Card>
                <Card.Header style={{backgroundColor: "#FBAA34", color: "white"}}>
                  <h3 style={{textAlign:'left', float:'left'}}>Register User</h3>
                  <OverlayTrigger
                      placement="top"
                      delay={{ show: 150, hide: 300 }}
                      overlay={(
                      <Popover id="popover-basic">
                          <Popover.Content>
                              <div>Please use form to register a business user <b>belonging to your organisation</b>. This user will receive temporary credentials and sign-up instructions via email. If you want to affiliate an external user with your organisation please navigate to the <b>External User Management</b> tab. </div>
                          </Popover.Content>
                      </Popover>
                      )} >
                      <h5 style={{textAlign:'right', float:'right'}}><FontAwesomeIcon icon={faInfoCircle}/></h5>
                  </OverlayTrigger>
                </Card.Header>
                <Card.Body>
                <Form>
                    <Form.Group as={Row} controlId="formTextCSOIdentifier">
                      <Form.Label column sm="8">
                        CSO Identifier
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control type="text" readOnly defaultValue={user.cso_identifier} />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="username">
                      <Form.Label column sm="8">
                        Username
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control 
                          type="text" 
                          onChange={getUsername}
                          value={usernameState}
                        />
                      </Col>
                    </Form.Group>
      
                    <Form.Group as={Row} controlId="userEmail">
                      <Form.Label column sm="8">
                        Email Address
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control 
                          type="text" 
                          onChange={getEmail}
                          value={emailState}
                        />
                      </Col>
                    </Form.Group>
                    <div style={{float: "right"}} className="pt-2 pr-3">
                        <Button 
                          disabled={emailState ==="" || usernameState ===""} 
                          onClick={() => {
                            dispatch(registerBusinessUser(emailState, usernameState));
                            }}
                          variant="info">Register Internal User</Button>
                    </div>
                    <div>
                      <Alert show={success} variant="success">
                        <Alert.Heading>Thanks!</Alert.Heading>
                          <p>
                            We have sent an email to this user with temporary login details and instructions. These details will expire in 7 days.
                          </p>
                          <div>
                            <Button onClick={() => {
                              dispatch(resetUIMessage())
                              setUsernameState('')
                              setEmailState('')
                              }}
                              variant="success">
                              Register another user
                            </Button>
                          </div>
                      </Alert>
                    </div>
                </Form>
                </Card.Body>
              </Card>
            </Container>
              </Col>
            <Col>
            <Container className="user-action">
                <Card>
                  <Card.Header style={{backgroundColor: "#FBAA34", color: "white"}}>
                    <h3 style={{textAlign:'left', float:'left'}}>Other User Actions</h3>
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 150, hide: 300 }}
                      overlay={(
                      <Popover id="popover-basic">
                          <Popover.Content>
                              Allocate internal users to surveys, change access permissions, and view currently allocated surveys.
                          </Popover.Content>
                      </Popover>
                      )} >
                      <h5 style={{textAlign:'right', float:'right'}}><FontAwesomeIcon icon={faInfoCircle}/></h5>
                  </OverlayTrigger>
                  </Card.Header>
                  
                  <Card.Body>
                  <div><UsersList /></div>
                  </Card.Body>

                </Card>
            </Container>
          </Col>
        </Row>
      </div>
    );
  }
  else {
    return(
    <Redirect to="/homepage"/>
    )
  }
}
