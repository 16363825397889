import { createSlice } from '@reduxjs/toolkit';
import API from '../../services/api';
import { BUSINESS_API_URL } from '../../config/service';
import { showError } from '../messages/messagesSlice';

export const affiliatedOrgsListSlice = createSlice({
    name: 'affiliatedorgslist',
    initialState: {
        affiliatedorgslist: []
    },
    reducers:{
        setAffiliatedOrgsList: (state, action) =>{
            if(action.payload){
                state.affiliatedorgslist = action.payload;
            }
        }
    }
});

export const { setAffiliatedOrgsList } = affiliatedOrgsListSlice.actions;

export const  listAffiliatedOrgs  = () => dispatch => {
    try{
        API.post(`${BUSINESS_API_URL}/v1/business/externalorgs`)
        .then((response)=>dispatch(setAffiliatedOrgsList(response.data))).catch((err)=>(console.log(err)))
    }
    catch(error){
        dispatch(showError({"message": `Listing Organization failed. ${error.message}`}));
    }
}

export const selectAffiliatedOrgs = state => state.affiliatedorgslist.affiliatedorgslist;
export default affiliatedOrgsListSlice.reducer;