import React, { useState }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReCAPTCHA from "react-google-recaptcha";
import { G_RECAPTCHA_CLIENT_ID } from "../../config/extra";
import { Form, Col, Container, Row, Button, Alert, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { selectUser } from '../auth/authSlice';
import { selectSendMessage, sendMessageAction } from '../sendmessage/sendMessageSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export function Contacts() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  //const [error, setError] = useState(false);
  //const [isLoggedOut, setIsLoggedOut] = useState(false);
  const {sent} = useSelector(selectSendMessage)

  function useFormFields(initialState) {
    const [fields, setValues] = useState(initialState);
    return [
      fields,
      function(event) {
        setValues({
          ...fields,
          [event.target.id]: event.target.value
        });
      },
      function(value) {
        setValues({
          ...fields,
          "formRecaptcha": value
        });
      }
    ];
  }

  const [fields, handleFieldChange, handleRecaptcha] = useFormFields({
     formComment: "",
     formReplyEmail: user.email,
     formRecaptcha: ""
  });

  // const displayToolTip = {!fields.formComment || !fields.formRecaptcha}

  const tooltip = (
    <Tooltip id="tooltip">Please verify and provide a message you want to convey.</Tooltip>
  );
  const shouldShowTooltip = !fields.formComment || !fields.formRecaptcha;
  return (
    <div>
      <Container className="pt-5">
        <Card>
          <Card.Header style={{backgroundColor: "#FBAA34", color: "white"}}><h3>Contact us</h3></Card.Header>
          <Card.Body>
          <Card.Text>
            Have you got an enquiry on a survey or a management issue? <br/>
            Drop a message to the CSO team and you will be contacted privately.
          </Card.Text>
          <Form>
              <Form.Group as={Row} controlId="formCSOIdentifier">
                <Form.Label column sm="8">
                  Your CSO Identifier
                </Form.Label>
                <Col sm="10">
                  <Form.Control type="text" readOnly defaultValue={user.cso_identifier}/>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formReplyEmail">
                <Form.Label column sm="8">
                  Reply Email
                </Form.Label>
                <Col sm="10">
                  <Form.Control type="text"
                    defaultValue={user.email}
                    onChange={handleFieldChange}
                    disabled={sent}
                   />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formComment">
                <Form.Label column sm="8">
                  Comment *
                </Form.Label>
                <Col sm="10">
                  <Form.Control as="textarea"
                    rows={3}
                    onChange={handleFieldChange}
                    placeholder="I would like to be contacted by CSO in regard to..."
                    disabled={sent}
                    />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formRecaptcha" className={sent ? "d-none" : ""}>
                <Col sm="12" className = "mx-auto"  >
                  <div style={{width: "164px"}} className = "mx-auto">
                    <ReCAPTCHA
                      sitekey={G_RECAPTCHA_CLIENT_ID}
                      onChange={handleRecaptcha}
                      size="compact"
                    />
                  </div>
                </Col>
              </Form.Group>


              <Alert show={sent} variant="success">
                <Alert.Heading>Thanks!</Alert.Heading>
                <p>
                  We have successfully received your message and we will be contacting you shortly through your preferred contact method.
                </p>
              </Alert>
            {shouldShowTooltip ? <div style={{float: "right"}} className="pt-2 pr-2">
                <OverlayTrigger
                    placement="top"
                    overlay={tooltip}
                    trigger="hover"
                    // show={!fields.formComment || !fields.formRecaptcha}
                  >
                <div className={`d-inline ${sent ? "d-none" : ""}`}>
                  <Button
                    disabled={!fields.formComment || !fields.formRecaptcha}
                    onClick={() => dispatch(sendMessageAction(fields.formComment, fields.formReplyEmail, fields.formRecaptcha))}
                    variant="info"
                  >
                    Send Message
                  </Button>
              </div>
            </OverlayTrigger>
          </div> : <div style={{float: "right"}} className="pt-2 pr-2">
                  <Button
                    disabled={!fields.formComment || !fields.formRecaptcha}
                    onClick={() => dispatch(sendMessageAction(fields.formComment, fields.formReplyEmail, fields.formRecaptcha))}
                    variant="info"
                  >
                    Send Message
                  </Button>
              </div>  }
          </Form>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}
