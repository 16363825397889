import React, { Fragment } from "react"
import { useTable, useSortBy, useFilters, usePagination, useExpanded } from "react-table"
import { Table, Row, Col, Button } from "reactstrap"
import { Filter, DefaultColumnFilter } from './Filters';
import { selectEqPortalAccess } from '../eqportalaccess/eqPortalAccessSlice';
import { useSelector } from "react-redux";

const TableContainer = ({ columns, data, renderRowSubComponent }) => {
const eqportalaccess = useSelector(selectEqPortalAccess)
let hiddenColumns;
if(eqportalaccess)
{
    hiddenColumns = []
}
else
{
    hiddenColumns = ['launch','submission_status']
}
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
      {
        columns,
        data,
        defaultColumn: { Filter: DefaultColumnFilter },
        initialState: { pageIndex: 0, hiddenColumns: hiddenColumns }
      },
      useFilters,
      useSortBy,
      useExpanded,
      usePagination
  )

  return (
    <Fragment>
        <Table responsive striped bordered hover {...getTableProps()}>
        <thead>
            {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                <div {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                </div>
                <Filter column={column} />
                </th>
                ))}
            </tr>
            ))}
        </thead>

        <tbody {...getTableBodyProps({style:{ height: "3vw" }})}>
            {page.map(row => {
            prepareRow(row)
            return (
                <Fragment key={row.getRowProps({style: {maxHeight: 5}}).key}>
                    <tr>
                    {row.cells.map(cell => {
                        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    })}
                    </tr>
                    {row.isExpanded && (
                    <tr>
                        <td colSpan={visibleColumns.length}>{renderRowSubComponent(row)}</td>
                    </tr>
                    )}
              </Fragment>
            )
            })}
        </tbody>
        </Table>
        <Row xs={0} style={{ height: 25, maxWidth: 400, margin: "0 auto", textAlign: "left" }}>
            <Col xs={0}>
                <Button
                    color="primary"
                    onClick={previousPage}
                    disabled={!canPreviousPage}
                >
                    {"Previous"}
                </Button>
            </Col>
            <Col xs={0} style={{ marginTop: 7 }}>
                Page{" "}
                <strong>
                    {pageIndex + 1} of {pageOptions.length=== 0 ? (1) : (pageOptions.length)}
                </strong>
            </Col>
            {/* <Col xs={2}>
                <Input
                    type="number"
                    min={1}
                    style={{ width: 70 }}
                    max={pageOptions.length}
                    defaultValue={pageIndex + 1}
                    onChange={onChangeInInput}
                />
            </Col> */}
            {/* <Col xs={2}>
                <CustomInput id="4" type="select" value={pageSize} onChange={onChangeInSelect}>
                    {[4, 6, 8, 10, 12, 14].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                    </option>
                    ))}
                </CustomInput>
            </Col> */}
            <Col xs={0}>
                <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
                    {"Next"}
                </Button>
            </Col>
         </Row>
    </Fragment>
  )
}

export default TableContainer