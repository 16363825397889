import { createSlice } from '@reduxjs/toolkit';
import API from '../../services/api';
import {USERS_API_URL} from '../../config/service';
import { showError, showSuccess } from '../messages/messagesSlice';

export const revokeAdminSlice = createSlice({
  name: 'revokeadmin',
  initialState: {
    value: '',
  },
  reducers: {
    revokeAdminStatus: (state, action) => {
      state.value = action.payload;
    }
  },
});

export const { revokeAdminStatus } = revokeAdminSlice.actions;

export const revokeAdminUser = username => dispatch => {
    API.put(`${USERS_API_URL}/v1/users/revoke`, {
        username: username,
      }).then((response) => {
        dispatch(showSuccess({ "message": "User's admin privileges have been revoked." }));
    }).catch((error) => {
        dispatch(showError({ "message": `User's privileges could not be revoked. ${error.message}` }));
    });
};

export const selectRevokeAdminStatusSelector = state => state.revokeadmin.value;

export default revokeAdminSlice.reducer;
