import { createSlice } from '@reduxjs/toolkit';
import API from '../../services/api';
import {SURVEYS_API_URL} from '../../config/service';
import { showError } from '../messages/messagesSlice';

const initialState = {
    allocatedsurveydata: []
  }

export const allocatedSurveyDataSlice = createSlice({
  name: 'allocatedsurveydata',
  initialState: {
    allocatedsurveydata: [],
  },
  reducers: {
    setAllocatedSurveyData: (state, action) => {
        if (action.payload)
        {
          return{
            ...state,
            allocatedsurveydata: [...state.allocatedsurveydata, action.payload]
          }     
        }
    },
    resetAllocatedSurveyData: state => initialState
  },
});

export const { setAllocatedSurveyData, resetAllocatedSurveyData } = allocatedSurveyDataSlice.actions;

export const unlistAllocatedSurveyData = () => dispatch => {dispatch(resetAllocatedSurveyData())};

export const listAllocatedSurveyData = (surveyString) => dispatch => {
    try {
          API.get(`${SURVEYS_API_URL}/v1/surveys/query/${surveyString}`)
          .then(function (response){ //dispatch(setAllocatedSurveyData(response.data.Item)));
          if(response.data.Items.length === 1)
          {
            dispatch(setAllocatedSurveyData(response.data.Items[0]));
          }

        });
    }
    catch(error) {
        dispatch(showError({ "message": `Survey data retrieval failed. ${error.message}` }));
    };
};

export const selectAllocatedSurveyData = state => state.allocatedsurveydata.allocatedsurveydata;
export default allocatedSurveyDataSlice.reducer;
