import { createSlice } from '@reduxjs/toolkit';
import API from '../../services/api';
import {USERS_API_URL} from '../../config/service';
import { showError } from '../messages/messagesSlice';

const initialState = {
  groups: []
}

export const userGroupsSlice = createSlice({
  name: 'groups',
  initialState: {
    groups: [],
  },
  reducers: {
    listGroups: (state, action) => {
        if (action.payload)
        {
            state.groups = action.payload;
        }
    },
      resetUsersGroups: state => initialState
  },
    
});

export const { listGroups, resetUsersGroups } = userGroupsSlice.actions;

export const unlistUsersGroups = () => dispatch => {dispatch(resetUsersGroups())};

export const userGroups = username => dispatch => {
    try {
          API.get(`${USERS_API_URL}/v1/users/groups/${username}`)
          .then((response) => dispatch(listGroups(response.data.Groups)));
    }
    catch(error) {
        dispatch(showError({ "message": `List user's groups failed failed. ${error.message}` }));
    };
};

export const selectGroups = state => state.groups.groups;

export default userGroupsSlice.reducer;
