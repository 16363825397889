import React, { Fragment } from "react"
import { useTable, useSortBy, useFilters, usePagination, useExpanded } from "react-table"
import { Table, Row, Col, Button, Input, CustomInput } from "reactstrap"
import { Filter, DefaultColumnFilter } from './Filters';

const TableContainer = ({ columns, data, renderRowSubComponent }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
      {
        columns,
        data,
        defaultColumn: { Filter: DefaultColumnFilter },
        initialState: { pageIndex: 0, pageSize: 10 }
      },
      useFilters,
      useSortBy,
      useExpanded,
      usePagination
  )

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }
  
  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  return (
    // If you're curious what props we get as a result of calling our getter functions (getTableProps(), getRowProps())
    // Feel free to use console.log()  This will help you better understand how react table works underhood.
    <Fragment>
        <Table responsive striped bordered hover {...getTableProps()}>
        <thead>
            {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                <div {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                    {generateSortingIndicator(column)}
                </div>
                <Filter column={column} />
                </th>
                ))}
            </tr>
            ))}
        </thead>

        <tbody {...getTableBodyProps({style:{ height: "10vw" }})}>
            {page.map(row => {
            prepareRow(row)
            return (
                <Fragment key={row.getRowProps({style: {maxHeight: 5}}).key}>
                    <tr>
                    {row.cells.map(cell => {
                        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    })}
                    </tr>
                    {row.isExpanded && (
                    <tr>
                        <td colSpan={visibleColumns.length}>{renderRowSubComponent(row)}</td>
                    </tr>
                    )}
              </Fragment>
            )
            })}
        </tbody>
        </Table>
        <Row xs={6} style={{ height: 25, maxWidth: 820, margin: "0 auto", textAlign: "center" }}>
            <Col xs={2}>
                <Button
                    color="primary"
                    onClick={previousPage}
                    disabled={!canPreviousPage}
                >
                    {"Previous"}
                </Button>
            </Col>
            <Col xs={2} style={{ marginTop: 7 }}>
                Page{" "}
                <strong>
                    {pageIndex + 1} of {pageOptions.length=== 0 ? (1) : (pageOptions.length)}
                </strong>
            </Col>
            <Col xs={2}>
                <Input
                    type="number"
                    min={1}
                    style={{ width: 70 }}
                    max={pageOptions.length}
                    defaultValue={pageIndex + 1}
                    onChange={onChangeInInput}
                />
            </Col>
            <Col xs={2}>
                <CustomInput type="select" value={pageSize} onChange={onChangeInSelect}>
                    {[10, 20, 30, 40, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                    </option>
                    ))}
                </CustomInput>
            </Col>
            <Col xs={2}>
                <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
                    {"Next"}
                </Button>
            </Col>
         </Row>
    </Fragment>
  )
}

export default TableContainer