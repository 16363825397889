import { createSlice } from '@reduxjs/toolkit';

export const removeSlice = createSlice({
  name: 'removeaccount',
  initialState: {
    triggerRemoveAccount: false
  },
  reducers: {
    deleteAccount: (state, action) => {
        state.triggerRemoveAccount = true;
    },
    deleteAccountTriggered: (state, action) => {
      state.triggerRemoveAccount = false;
    }
  },
});

export const { deleteAccount, deleteAccountTriggered } = removeSlice.actions;

export const selectTriggerRemoveAccount = state => state.removeaccount.triggerRemoveAccount;

export default removeSlice.reducer;
