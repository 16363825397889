import React from "react";
import Table from 'react-bootstrap/Table';
import {Link} from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { selectAllocatedSurveys } from "../allocatedsurveylist/allocatedSurveyListSlice";

export const AllocatedSurveyDataCso = () => {

  const allocatedsurveys = useSelector(selectAllocatedSurveys);

  return (
    allocatedsurveys && allocatedsurveys.length > 0 ? (
      <Table responsive striped bordered hover size="sm">
          <thead>
            <tr>
              <td><b>Survey Title</b></td>
              <td><b>Survey ID</b></td>
              <td><b>Survey Start Date</b></td>
              <td><b>Survey End Date</b></td>
              <td><b>Upload Template</b></td>
              <td><b>Download Templates</b></td>
            </tr>
          </thead>
        {allocatedsurveys ? (
          <tbody>
            {allocatedsurveys.map((surveyRow, key) => {

              return (
                  <tr key={key}>
                    <td>{surveyRow.author_title}</td>
                    <td>{surveyRow.survey_id}</td>
                    <td>{surveyRow.survey_start_date}</td>
                    <td>{surveyRow.survey_end_date}</td>
                    <td>
                      <div style={{ textAlign: "center" }} >
                        <Link to ={`/home/uploadcso/${surveyRow.survey_id}`} >
                          <Button variant="secondary">Upload File</Button>
                        </Link>
                      </div>
                    </td>
                    <td>
                  <div style={{ textAlign: "center" }} >
                    <Link to={`/home/download/${surveyRow.survey_id}`} >
                      <Button variant="warning">Download</Button>
                    </Link>
                  </div>
                </td>
                  </tr>
                  )
            })}
          </tbody>
        ) : (
          <div>Loading...</div>
        )}
      </Table>
    ) : (
      <div>&nbsp;No surveys have been allocated to you.</div>
    )
  );
};

export default AllocatedSurveyDataCso;
