import { createSlice } from '@reduxjs/toolkit';
import API from '../../services/api';
import {SURVEYS_API_URL} from '../../config/service';
import { showError, showSuccess } from '../messages/messagesSlice';

export const allocateSurveySlice = createSlice({
  name: 'allocatesurvey',
  initialState: {
    value: '',
    allocateMessage: false,
    unallocateMessage: false
  },
  reducers: {
    allocateSurveyStatus: (state, action) => {
      state.value = action.payload;
    },
    surveyAllocated: state => {
      state.allocateMessage = true
    },
    surveyUnallocated: state => {
      state.unallocateMessage = true
    },
    resetAllocatedMessage: state => {
      state.allocateMessage = false
    },
    resetUnallocatedMessage: state => {
      state.unallocateMessage = false
    }
  },
});

export const { allocateSurveyStatus, surveyAllocated, surveyUnallocated, resetAllocatedMessage, resetUnallocatedMessage } = allocateSurveySlice.actions;

export const allocateSurvey = (username, surveyString, surveyName) => dispatch => {
    API.put(`${SURVEYS_API_URL}/v1/surveys/allocate/user/${surveyString}`, {
        username: username,
        surveyName: surveyName
      }).then((response) => {
        dispatch(showSuccess({ "message": "This survey has been allocated successfully to: " + username }));
        dispatch(surveyAllocated())
    }).catch((error) => {
        dispatch(showError({ "message": `Survey allocation failed. ${error.message}` }));
    });
};

export const selectAllocateSurveyStatusSelector = state => state.allocatesurvey.value;

export const selectAllocatedMessage = state => state.allocatesurvey.allocateMessage
export const selectUnallocatedMessage = state => state.allocatesurvey.unallocateMessage

export default allocateSurveySlice.reducer;
