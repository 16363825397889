import { createSlice } from '@reduxjs/toolkit';
import API from '../../services/api';
import {USERS_API_URL} from '../../config/service';
import { showError } from '../messages/messagesSlice';

export const usersListSlice = createSlice({
  name: 'userslist',
  initialState: {
    userslist: [],
  },
  reducers: {
    setList: (state, action) => {
        if (action.payload)
        {
            state.userslist = action.payload;
            state.isLoadingUsers = false;
        }
    }
  },
});

export const { setList } = usersListSlice.actions;

export const listUsers = cso_id => dispatch => {
    try {
          API.get(`${USERS_API_URL}/v1/users`)
          .then(function(response){
            dispatch(setList(response.data))
          });
    }
    catch(error) {
        dispatch(showError({ "message": `List users failed. ${error.message}` }));
    };
};

export const selectUsers = state => state.userslist.userslist;
export default usersListSlice.reducer;
