import React from 'react';
import { Link } from "react-router-dom";
import styles from './TermsAndConditions.module.scss';

export function TermsLink() {
    return (
        <div>
        <Link 
            className={styles.text_link}
            to="/terms" 
            target="_blank"
            >
                Click here to view terms and conditions
        </Link>
        </div>  
    );
}