import React from "react";
import { Button, Table } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { selectExternalUserTasks } from './externalUserTasksSlice';
import { unallocateSurveyExternal } from "../allocatesurvey/unallocateSurveyExternal";
import { listExternalUserTasks } from "./externalUserTasksSlice"
import { selectUser } from '../auth/authSlice';

export const ExternalUserTasksAdmin = (props) => {
  
  const dispatch = useDispatch();
  const externalusersurveys = useSelector(selectExternalUserTasks);
  const user = useSelector(selectUser);

  function byKeyStartDate(arr, key) {
    const keySK = key.SK
    for (var i = 0, L = arr.length; i < L; i++) {
      if (arr[i].SK === keySK) {
        if (arr[i].PINStartDate) {
          return arr[i].PINStartDate
        }
        else {
          return key.survey_start_date
        }
      }
    }
  }

  function byKeyEndDate(arr, key) {
    const keySK = key.SK
    for (var i = 0, L = arr.length; i < L; i++) {
      if (arr[i].SK === keySK) {
        if (arr[i].PINEndDate) {
          return arr[i].PINEndDate
        }
        else {
          return key.survey_end_date
        }
      }
    }
  }

  function dateFormat(longDate) {
    if (longDate && longDate !== "" && longDate !== " ") {
      const unixStamp = Date.parse(longDate);
      const newDate = new Date(unixStamp)
      //const readableDate = newDate
      const dateString = newDate.getDate().toString() +
        "/" + (newDate.getMonth() + 1).toString() +
        "/" + newDate.getFullYear().toString()

      return dateString;
    }
    else return null;
  }

  return (    
    externalusersurveys ? (
      <Table responsive striped bordered hover size="sm">
          <thead>
            <tr style={{ textAlign: "center" }}>
              <td><b>Survey ID</b></td>
              <td><b>Survey Title</b></td>
              <td><b>Start Date</b></td>
              <td><b>End Date</b></td>
              <td><b>Unallocate</b></td>
            </tr>
          </thead>
        {externalusersurveys.length > 0 ? (
          <tbody>
            {externalusersurveys.map((surveyRow, key) => {

              return (
                  <tr key={key} style={{ textAlign: "center"}}>
                    <td>{surveyRow.PK}</td>
                    <td>{surveyRow.author_title}</td>
                    <td>{dateFormat(byKeyStartDate(externalusersurveys, surveyRow))}</td> 
                    <td>{dateFormat(byKeyEndDate(externalusersurveys, surveyRow))}</td> 
                    <td>
                        <Button
                         onClick={() => {
                            dispatch(unallocateSurveyExternal(props.username, surveyRow.PK.split('SURVEY#')[1], surveyRow.author_title))
                            dispatch(listExternalUserTasks(props.username, user.cso_identifier))
                            }}
                          variant="danger"
                          >
                          Unallocate Survey
                        </Button>
                    </td>
                  </tr>
              )
            })}
          </tbody>
        ) : (
          <div>There are no surveys assigned to this user.</div>
        )}
      </Table>
    ) : (
      <div>&nbsp; Loading...</div>
    )
  );
};


