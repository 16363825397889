import API from '../../services/api';
import {SURVEYS_API_URL} from '../../config/service';
import { showError, showSuccess } from '../messages/messagesSlice';
import { surveyUnallocatedExternal } from "./allocateSurveyExternalSlice";

export const unallocateSurveyExternal = (username, surveyString, surveyName) => dispatch => {
    API.post(`${SURVEYS_API_URL}/v1/surveys/unallocate/external/${surveyString}`, {
        username: username,
        surveyName: surveyName
      }).then((response) => {
        dispatch(showSuccess({ "message": "This survey has been unallocated successfully from: " + username }));
        dispatch(surveyUnallocatedExternal())
    }).catch((error) => {
        dispatch(showError({ "message": `Survey unallocation failed. ${error.message}` }));
    });
};
