import { Auth } from 'aws-amplify';
import { useAuthenticator } from "@aws-amplify/ui-react";
import React, { useState } from 'react';
import { Redirect } from "react-router-dom";
import Button from 'react-bootstrap/Button'
import { setAuthState, resetState } from '../auth/authSlice';
import { useDispatch } from 'react-redux';

export function Signout() {

    const [logout, setLogout] = useState(false);
    const [error, setError] = useState(false);
    const [isLoggedOut, setIsLoggedOut] = useState(false);
    const { authStatus } = useAuthenticator(context => [context.authStatus]);
    
    const dispatch = useDispatch();

    async function logoutUser () {
        await setLogout(true);
        await Auth.signOut({ global: true }).catch((error)=>{ Auth.signOut()});
        await dispatch(setAuthState("unauthenticated"))
        await dispatch(resetState());
        //await setIsLoggedOut(true);
    };
    
    React.useEffect(() => {
        if(logout) {
            setIsLoggedOut(true);
        }
    }, [logout])

    if(error) {
        //TODO: show error or modal
    } else if(isLoggedOut) {
        return (
            <Redirect to="/login" />
        )
    } else {
        return (
            <div>
                <Button onClick={logoutUser} variant="primary">Sign Out</Button>
            </div>
        );
    }
}
