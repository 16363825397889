import { createSlice } from '@reduxjs/toolkit';
import API from '../../services/api';
import {BUSINESS_API_URL} from '../../config/service';
import { showError } from '../messages/messagesSlice';

export const csoAllBusinessSlice = createSlice({
  name: 'csoallbusiness',
  initialState: {
    csoallbusiness: [],
  },
  reducers: {
    setCsoAllBusiness: (state, action) => {
        if (action.payload)
        {
            state.csoallbusiness = action.payload;
        }
    }
  },
});

export const { setCsoAllBusiness } = csoAllBusinessSlice.actions;

export const csoAllBusiness = () => dispatch => {
    try {
          API.get(`${BUSINESS_API_URL}/v1/business/all/cso`)
          .then((response) => dispatch(setCsoAllBusiness(response.data)));
    }
    catch(error) {
        dispatch(showError({ "message": `List all business failed. ${error.message}` }));
    };
};

export const selectCsoAllBusiness = state => state.csoallbusiness.csoallbusiness;
export default csoAllBusinessSlice.reducer;
