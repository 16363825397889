import React from "react";
import { Button, Table } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { selectAffiliatedOrgs } from "./affiliatedOrgsListSlice";
import { updateStatus } from './updateAffiliationStatusSlice';
import {useDispatch} from 'react-redux';

export const AffiliatedPendingOrgs = () => {

  const affiliatedorgs = useSelector(selectAffiliatedOrgs);
  const pendingorgs = affiliatedorgs.filter((item)=>item.Status.includes('PENDING'));

  const dispatch = useDispatch();

  return (    
    pendingorgs.length > 0 ? (
      <Table responsive striped bordered hover size="sm">
          <thead>
            <tr style={{ textAlign: "center" }}>
              <td><b>Organization ID</b></td>
              <td><b>Organization</b></td>
              <td><b>Status</b></td>
              <td><b>Affiliation Action</b></td>
            </tr>
          </thead>
          
        {pendingorgs ? (
          <tbody>
            {pendingorgs.map((surveyRow, key) => {

              return (
                  <tr key={key} style={{ textAlign: "center"}}>
                    <td>{surveyRow.ORGID.slice(4,)}</td>
                    <td>{surveyRow.COMPANY_NAME}</td>
                    <td>{surveyRow.Status}</td> 
                    <td>
                    <Button 
                      variant='primary'
                      onClick={() => {
                        dispatch(updateStatus(surveyRow.ORGID.slice(4,), "ACTIVE"));
                        }}
                      >
                      Accept
                      </Button><b style={{ padding: "10px" }}></b>
                    <Button 
                      variant='danger'
                      onClick={() => {
                        dispatch(updateStatus(surveyRow.ORGID.slice(4,), "REJECTED"));
                        }}
                      >Reject</Button></td>
                  </tr>
              )
            })}
          </tbody>
        ) : (
          <div>Loading...</div>
        )}
      </Table>
    ) : (
      <div>&nbsp; You have no pending affiliation requests.</div>
    )
  );
};


