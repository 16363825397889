import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { Authenticator } from '@aws-amplify/ui-react'
import { Header } from './features/header/Header';
import { Footer } from './features/footer/Footer';
import { FooterPadding } from './features/footer/FooterPadding';
import { NavigationBar } from './features/navigationbar/NavigationBar';
import { Home } from './features/home/Home';
import { PrivateRoute } from './components/PrivateRoute';
import { AuthStateApp } from './features/auth/Auth';
import { CookieModal } from './features/cookiemodal/CookieModal';
import './App.scss';
import { TermsPage } from './features/termsandconditions/TermsPage';
import { Messages } from './features/messages/Messages';
import { Alert } from './features/alert/Alert';
import { SessionTimeout } from './components/SessionTimeout';

function App() {

  return (
    <Authenticator.Provider>
    <Router>
      <div className="App">
        <Header/>
        <NavigationBar/>
        <Switch>
          <Route path="/login">
            <AuthStateApp/>
          </Route>
          <Route path="/terms">
            <TermsPage/>
          </Route>
          <PrivateRoute path="/home">
            <Home/>
          </PrivateRoute>
          <Route path="*">
            <Redirect to="/home"/>
          </Route>
        </Switch>
        <CookieModal/>
        <Messages/>
        <FooterPadding/>
        <Footer/>
        <Alert/>
      </div>
      <SessionTimeout />
    </Router>
    </Authenticator.Provider>
  );
}

export default App;
