import { createSlice } from '@reduxjs/toolkit';
import API from '../../services/api';
import {SURVEYS_API_URL} from '../../config/service';
import { showError } from '../messages/messagesSlice';

const initialState = {
    userssurveydata: []
  }

export const usersSurveyDataSlice = createSlice({
  name: 'userssurveydata',
  initialState,
  reducers: {
    setUsersSurveyData: (state, action) => {
        if (action.payload)
        {
          return{
            ...state,
            userssurveydata: [...state.userssurveydata, action.payload]
          }     
        }
    },
    resetUsersSurveyData: state => initialState
  },
});

export const { setUsersSurveyData, resetUsersSurveyData } = usersSurveyDataSlice.actions;

export const unlistUsersSurveyData = () => dispatch => {dispatch(resetUsersSurveyData())};

export const listUsersSurveyData = (surveyString) => dispatch => {
    try {
          API.get(`${SURVEYS_API_URL}/v1/surveys/query/${surveyString}`)
          .then(function (response){
          if(response.data.Items.length === 1)
          {
            dispatch(setUsersSurveyData(response.data.Items[0]));
          }

        });
    }
    catch(error) {
        dispatch(showError({ "message": `Survey data retrieval failed. ${error.message}` }));
    };
};

export const selectUsersSurveyData = state => state.userssurveydata.userssurveydata;
export default usersSurveyDataSlice.reducer;
