import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    //"signedin" or "signedout"
    authState: "unauthenticated",
    user: {}
  },
  reducers: {
    setAuthState: (state, action) => {
        state.authState = action.payload;
    },
    setUser: (state, action) => {
        if ( action.payload ) {
          state.user.username = action.payload.username;
          if( action.payload.signInUserSession && action.payload.signInUserSession.idToken && action.payload.signInUserSession.idToken.payload ) {

            let temp = action.payload.signInUserSession.idToken.payload;
            state.user.email = temp.email;
            state.user.cso_identifier = temp['custom:cso_identifier'];
            state.user.groups = temp['cognito:groups'];
          }
        }
    },
    resetState: state => {}
  },
  
});

export const { setAuthState, setUser, resetState } = authSlice.actions;

export const selectUser = state => state.auth.user;
export const selectAuthState = state => state.auth.authState;

export default authSlice.reducer;
