import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Card, Button, Col, OverlayTrigger, Popover } from 'react-bootstrap';
import { CardBody, CardTitle } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle, faInfoCircle, faEyeSlash, faEye} from '@fortawesome/free-solid-svg-icons'
import { SelectColumnFilter } from './Filters';
import "bootstrap/dist/css/bootstrap.min.css"
import { selectSurveyHistory } from './surveyHistorySlice';
import { selectSendMessage, requestSubmission } from '../sendmessage/sendMessageSlice';
import { selectUser } from '../auth/authSlice';
import TableContainer from "./TableContainer"
import { selectSurveySubmissions } from './surveySubmissionsSlice';
import { listSurveySubmissions } from '../surveyhistory/surveySubmissionsSlice';
import { SurveySubmission } from './surveySubmission';

export function SurveyHistory() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const {request} = useSelector(selectSendMessage)
  const surveyHistory = useSelector(selectSurveyHistory);
  const surveySubmissions = useSelector(selectSurveySubmissions);
  let data = []

  React.useEffect(() => {

    dispatch(listSurveySubmissions());

  }, [dispatch])

  function dateFormat(longDate)
  {
    if(longDate && longDate !== "" && longDate!== " ")
    {
      const unixStamp = Date.parse(longDate);
      const newDate = new Date(unixStamp).toISOString().substring(0, 10)
      const newTime = new Date(unixStamp).toISOString().substring(11, 19)
      const readableDate = newDate + " " + newTime;
      return readableDate;
    }
    else return null;
  } 

  if (surveyHistory && surveyHistory.length > 0)
  {
    surveyHistory.slice(1, surveyHistory.length).map((surveyRow, key) => {
    return data.push({
      survey_title: surveyRow.Data[0].VarCharValue,
      last_accessed: dateFormat(surveyRow.Data[1].VarCharValue),
      no_of_submissions: surveyRow.Data[2].VarCharValue,
      last_submission: dateFormat(surveyRow.Data[3].VarCharValue),
      last_submission_user: surveyRow.Data[4].VarCharValue,
      user_ids: surveyRow.Data[5].VarCharValue,
      status: surveyRow.Data[6].VarCharValue,
      request: surveyRow.Data[0].VarCharValue //User can request a copy. Flag to prevent him sending multiple requests
      })
    })
  }

  const renderRowSubComponent = row => {

   const subs = surveySubmissions.filter(obj => {
      return obj.Data[0].VarCharValue === row.original.survey_title
    })

    return (
      subs.length === 0 ? (
        <div style={{margin: "0 auto", textAlign: "center"}}> No submission data available </div>
        ) : (
        <Card style={{ width: "60rem", margin: "0 auto" }}>
          <CardBody>
            <CardTitle>
              <strong>Submissions</strong>
            </CardTitle>
            <SurveySubmission submissions={subs} />
          </CardBody>
        </Card>
      )
    )
  }

  const columns = useMemo(
    () => [
    {
      Header: 'Survey title',
      accessor: 'survey_title'
    },{
      Header: 'Last Accessed',
      accessor: 'last_accessed'
    },{
      Header: 'No. of Submissions',
      accessor: 'no_of_submissions'
    },{
      Header: () => null,
      id: 'expander', // 'id' is required
      Cell: ({ row }) => (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? <FontAwesomeIcon icon={faEyeSlash} title="Toggle Row Exapnded" /> : <FontAwesomeIcon icon={faEye} title="Toggle Row Hidden" />}
        </span>
      )
    },
    {
      Header: 'Last Submission',
      accessor: 'last_submission'
    },{
      Header: 'Last Submission User',
      accessor: 'last_submission_user'
    },{
      Header: 'Users Accessed',
      accessor: 'user_ids'
    },{
      Header: 'Status',
      accessor: 'status',
      Filter: SelectColumnFilter,
      filter: 'equals'
    },
    {
      disableFilters: true,
      disableSortBy: true,
      Header: () => (
        <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={(
              <Popover id="popover-basic">
                <Popover.Content>
                  You can request a copy of your latest submission of this survey from the CSO.
                  The CSO Team will contact you directly as soon as possible.
                  You can only make this request once a day.
                </Popover.Content>
              </Popover>
            )} >
          <div>
            Request a copy <FontAwesomeIcon icon={faQuestionCircle} />
          </div>
        </OverlayTrigger> ),
        sortable: false, // use table default
        resizable: false, // use table default
        accessor: 'request',
        show: user.groups.includes('BUSINESS_ADMINS'),
        Cell: props => {
            return (
            <div style={{textAlign: "center"}} >
                <Button
                disabled={request[props.value] || props.row.original.status !== "Submitted"}
                onClick={()=>dispatch(requestSubmission(props.value))}
                variant="success" >
                { request[props.value] ? "Requested" : "Request" }</Button>
            </div>
                )
            }
        }
    ], 
    []
  )

  return (
    <Col style={{ paddingLeft: 50, paddingRight: 50 }}>
        <Container fluid className= 'pt-5'>
          <Card>
            <Card.Header style={{backgroundColor: "#1D345C", color: "white"}}>
                <h3 style={{textAlign:'left', float:'left'}}>Survey History &nbsp;</h3>
              <OverlayTrigger
                placement="top"
                delay={{ show: 150, hide: 300 }}
                overlay={(
                  <Popover id="popover-basic">
                    <Popover.Content>
                      <h6>The information in this table may take up to 1 business day to update.</h6>
                    </Popover.Content>
                  </Popover>
                )} >
                <h5 style={{textAlign:'right', float:'left'}}><FontAwesomeIcon icon={faInfoCircle}/></h5>
              </OverlayTrigger>
            </Card.Header>
            <Card.Body>
                <TableContainer columns={columns} data={data} renderRowSubComponent={renderRowSubComponent}/>
            </Card.Body>
          </Card>
        </Container>
    </Col>
  );
}
