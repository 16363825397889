import React from "react";
import { Form, Col, Container, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { useLocation } from "react-router-dom";
import { selectUser } from '../auth/authSlice';
import { useSelector } from 'react-redux';
import { UploaderCSO } from './uploaderComponentCSO';
import { selectCsoAllSurveys } from "../csoallsurveys/csoAllSurveysSlice";
import { selectAllocatedSurveys } from "../allocatedsurveylist/allocatedSurveyListSlice";

export const FileUploadCSO = () => {

    const location = useLocation();
    const selectedSurvey = location.pathname.split('uploadcso/')[1]
    const user = useSelector(selectUser)
    const csoAdminSurveys = useSelector(selectCsoAllSurveys);
    const csoUserSurveys = useSelector(selectAllocatedSurveys);

    let surveys;

    if (user){
      const userGroup = user.groups && user.groups.toString();
      if(userGroup.includes("CSO_ADMINS"))
      {
        surveys = csoAdminSurveys
      }
      else if(userGroup.includes("CSO_USERS"))
      {
        surveys = csoUserSurveys
      }
    }

    function byKeyPK(arr, key) {
      for ( var i=0, L=arr.length; i<L; i++ ) {
        if ( arr[i].survey_id === key ) {
          return arr[i].PK;
        }
      }
    }
    
    function byKeyName(arr, key) {
      for ( var i=0, L=arr.length; i<L; i++ ) {
        if ( arr[i].survey_id === key ) {
          return arr[i].author_title;
          
        }
      }
    }
    

  return (
    <div>
      <Container className="pt-5">
        <Card>
          <Card.Header style={{backgroundColor: "#FBAA34", color: "white"}}><h3>Upload Document</h3></Card.Header>
          <Card.Body>
            <Form>
              <Form.Group as={Row}>
                <Form.Label column sm="8">
                  Survey Title
                </Form.Label>
                <Col sm="10">
                  <Form.Control type="text" readOnly defaultValue={byKeyName(surveys, selectedSurvey)}/>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="8">
                    File to Attach
                </Form.Label>&nbsp;&nbsp;&nbsp;
              </Form.Group>
                <UploaderCSO surveyName={selectedSurvey} survey={byKeyPK(surveys, selectedSurvey)} org={user.cso_identifier}/>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </div> 
  );
}