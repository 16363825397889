import React, { useState }  from 'react';
import { Button, Form } from 'react-bootstrap';
import { showError, showInfo } from '../messages/messagesSlice';
import { showSuccess} from '../messages/messagesSlice';
import { showWarning} from '../messages/messagesSlice';
import { selectUser } from '../auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import API from '../../services/api';
import {SURVEYS_API_URL} from '../../config/service';

export const UploaderCSO = (props) => {

  const [fileState, setFileState] = useState({});
  const [fileNameState, setFileNameState] = useState('');
  const [messageState, setMessageState] = useState('');
  const [commentState, setCommentState] = useState('');
  const [fileSelected, setFileSelected] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(selectUser);
  let buttonText;
  let originalFileName;

  const fileTypes = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                      'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/xml', 'text/xml', 'text/csv',
                      'application/pdf', 'application/zip']

  if (commentState.trim() === '')
  {
    buttonText = "Upload"
  }
  else
  {
    buttonText = "Upload with comment"
  }

  const containsTwoDots = (filename) =>{
    const parts = filename.split('.');
    return parts.length > 2;
  }

  const charactersToCheck = [':', ';', '<', '>', '/', '\\', '%','$','*',];

  const getFile = (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      setMessageState('');
      setFileState({})
      setFileSelected(false);
      setFileNameState('')
      const file = files[0];
      originalFileName = file.name
      const type = file.type;
      const unixTime = Date.now();
      const stampedFileName = file.name.replace(/(\.[\w\d_-]+)$/i, '_'+unixTime+'$1')
      Object.defineProperty(file, 'name', { writeable: true, value: stampedFileName})

      if(file.size === 0)
      {
        setMessageState( "File: " + originalFileName + " is an empty file and cannot be uploaded." )
      }
      else if (file.size > 10000000)
      {
        setMessageState("File: " + originalFileName + " is too big to be uploaded, file must be less than 10MB." )
      }
      else if (charactersToCheck.some(char => originalFileName.includes(char)))
      {
        setMessageState("File: " + originalFileName + " contains invalid characters. Please note you cannot use these characters : , ; , < , > , / , \\  , % , $ , * " )
      }
      else if (containsTwoDots(originalFileName)){
        setMessageState('File: ' + originalFileName + ' is an invalid file type. The filename cannot have two dots in it.');
      }
      else if (fileTypes.includes(type))
      {
        setFileState({ file });
        setFileSelected(true);
        setFileNameState(originalFileName)
        // btnref.target.value=({file});
      }
      else 
      {
        setMessageState('File: ' + originalFileName + ' is an invalid file type. Accepted file types are: .doc, .docx, .xls, .xlsx, .xml, .csv, .pdf, .zip. If your file type is listed here and this message still appears please contact your organisation or IT department about any file upload restrictions that may be imposed.');
      }
    }
  };

  const getComment = (e) => {
    const comment = e.target.value;
    setCommentState(comment);
  };

  const uploadFile = (e) => {
    e.preventDefault();
    
    const { file } = fileState;
    const FileName = fileNameState;
    setMessageState('Uploading...')
    const folder = 'quarantine/' + props.survey + '/ORG#' + props.org + '/' + user.username + '/'
    const fileNameLessSpaces = file.name.replace(/ /g, "_");
    const keyString = folder + fileNameLessSpaces;
    const contentType = file.type;

      const urlOptions = {
        params: {
          Key: keyString,
          ContentType: contentType,
          Filename: file.name,
          Comment: commentState.trim()
        },
        headers: {
          'Content-Type': contentType
        }
      };

      API.get(`${SURVEYS_API_URL}/v1/surveys/s3/put`, urlOptions).then(res => {
          
          return res.data})
          .then(function(putURL) {

              fetch(putURL, {
                  method: 'PUT',
                  mode: 'cors',
                  body: file
                  }).then(function(res){

                      if(res.status === 200)
                      {
                        dispatch(showInfo({ "message": FileName + " is being scanned for viruses and malware..." }))
                        API.get(`${SURVEYS_API_URL}/v1/surveys/s3/scan`, urlOptions).then(function(scanRes){

                          if(scanRes.data.is_infected === true)
                          {
                            const dbOptions = {
                              params: {
                                cso_id: 'ORG#' + props.org,
                                survey_id: props.survey,
                                username: user.username,
                                comment: false,
                                file_name: file.name,
                                file_status: "infected/deleted"
                              }
                            };

                            API.get(`${SURVEYS_API_URL}/v1/surveys/s3/remove`, urlOptions)
                            dispatch(showWarning({ "message": FileName + " is infected and cannot be uploaded." }))
                            setMessageState('');
                            setFileState({})
                            setFileNameState('')
                            setFileSelected(false);
                            API.get(`${SURVEYS_API_URL}/v1/surveys/s3/record`, dbOptions)
                          }
                          else if(scanRes.data.is_infected === false)
                          {
                            const commentBool = commentState.trim() !== ""

                            const dbOptions = {
                              params: {
                                cso_id: 'ORG#' + props.org,
                                survey_id: props.survey,
                                username: user.username,
                                comment: commentBool,
                                file_name: file.name,
                                file_status: "clean/verified"
                              }
                            };

                            API.get(`${SURVEYS_API_URL}/v1/surveys/s3/copy`, urlOptions)
                            dispatch(showSuccess({ "message": FileName + " has been uploaded successfully." }))
                            const fileref = document.getElementById('upload-file');
                            fileref.value = '';
                            setMessageState("");
                            // setMessageState("File: " + file.name + " has been uploaded successfully.")
                            API.get(`${SURVEYS_API_URL}/v1/surveys/s3/record`, dbOptions);
                            if(commentBool)
                            {
                              API.get(`${SURVEYS_API_URL}/v1/surveys/s3/comment`, urlOptions).then(function(commentRes)
                              {
                                if(commentRes.status === 201)
                                {
                                  dispatch(showSuccess({ "message": "Your comment has been attached successfully." }))
                                }
                                else
                                {
                                  dispatch(showWarning({ "message": "Something went wrong, comment failed to attach. " }))
                                }
                              })
                            }
                          }
                          else 
                          {
                            API.get(`${SURVEYS_API_URL}/v1/surveys/s3/remove`, urlOptions)
                            dispatch(showError({ "message": "Process timed out, please try again."}))
                            
                          }
                        })
                      }
                    })
                  .catch((error) => {
                    dispatch(showError({ "message": error.message }));
                    setMessageState('');
                    setFileState({})
                    setFileNameState('')
                    setFileSelected(false);
                  });

                  setFileState({});
                  setFileNameState('')
                  setFileSelected(false);
                  setCommentState('');
                  setMessageState('');
            })
           
       };

    return (
      <Form.Group>
        <input
          id='upload-file'
          type='file'
          accept=".xls,.xlsx,.xml,.doc,.docx,.pdf,.csv,.zip"
          onChange={getFile}
        />
        <p>{messageState}</p>
        <p style={{color: "red"}}>The upload might take a while depending on the network's confirguration.</p>
        <Form.Group controlId="TextArea">
          <Form.Label>Comments (Optional)</Form.Label>
          <Form.Control
              as="textarea"
              rows={3}
              onChange={getComment}
              value={commentState}
           />
        </Form.Group>
        <div style={{float: "right"}} className="pt-2 pr-2">
            <Button
              onClick={() => history.goBack()}
              >Back
            </Button>
        </div>
        <div className="pt-2 pr-2" style={!fileSelected ?
                  {
                    float: "right",
                    opacity: "0.25",
                    style: "cursor: pointer"
                  } : 
                  {
                    float: "right"
                  }}>
    <Button variant='info' disabled={!fileSelected} onClick={uploadFile}>{buttonText}</Button>
        </div>
      </Form.Group>
      
    );
}