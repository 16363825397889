import React from "react";
import { Amplify, Auth, I18n } from 'aws-amplify';
import { Authenticator, useAuthenticator, translations } from '@aws-amplify/ui-react';
import "../../styles.css";
import { getAuthConfig } from "../../config/auth";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { setAuthState, setUser, selectUser, selectAuthState } from './authSlice';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from "react-router-dom";
import { TermsLink } from '../termsandconditions/TermsLink';
import { isCookieEnabled } from '../cookiemodal/cookieSlice';

Amplify.configure(getAuthConfig());
I18n.putVocabularies(translations);

I18n.putVocabulariesForLanguage('en', {
  "User already exists" : "A user already exists with this username. Username must be unique. Please enter a different username and try again.",
  "PreSignUp failed with error 'Item'." : "CSO Identifier and PIN combination is incorrect. Please try again or contact your administrator for help.",
  "PreSignUp failed with error CSO ID or PIN is incorrect." : "CSO Identifier and PIN combination is incorrect. Please try again or contact your administrator for help.",
  "PreSignUp failed with error Username cannot be more than 32 characters ." : "Username cannot be more than 32 characters. Please enter a different username and try again.",
  "PreSignUp failed with error Username cannot contain invalid characters.": "Username cannot contain the following characters: <>/\":\\|?* . Please enter a different username and try again.",
  "Custom auth lambda trigger is not configured for the user pool.":"Error logging in. Ensure username and password are entered correctly. If this error persists please refresh the page.",
  "Username cannot be empty":"Username cannot be empty. If this error persists after entering your username please refresh the page and try again.",
  "1 validation error detected: Value at 'username' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+" : "Username must be continuous with no spaces.", 
  "1 validation error detected: Value null at 'attributeName' failed to satisfy constraint: Member must not be null" : "Please click the checkbox and verify your email or click skip.",
  "Please confirm your Password":"Confirm your password",
  "Username cannot be of email format, since user pool is configured for email alias.":"Username cannot be of email format."
});

const formFields = {
    signIn: {
      username: {
        placeholder: "Enter your username",
        isRequired: true,
        label: "Username:"
      },
      password: {
        placeholder: "Enter your password",
        isRequired: true,
        label: "Password:"
      }
    },
  
    signUp: {
      "custom:cso_identifier": {
        label: "CSO Identifier:",
        placeholder: "Enter your CSO identifier",
        required: true
      },
      "custom:PIN": {
        label: "PIN:",
        placeholder: "Enter your PIN",
        required: true
      },
      "custom:company_name": {
        label: "Company Name (Optional):",
        placeholder: "Enter your company name",
        required: false,
        descriptiveText: "If you are registering as an external user please enter the name of the company that you work for"
      },
      email: {
        placeholder: "Enter your email",
        isRequired: true,
        label: "Email:",
        onChange: e => {
          e.target.value = e.target.value.toLowerCase();
        }
      },
      username: {
        placeholder: "Enter your username",
        isRequired: true,
        label: "Username:",
        descriptiveText: "Username must be unique and must be lesser than 32 characters. Please choose a username that is derivative of your given name. i.e. John Smith: jsmith4"
      },
      name: {
        placeholder: "Enter your name",
        isRequired: true,
        label: "Name:"
      },
      password: {
        placeholder: "Enter your password",
        isRequired: true,
        label: "Password:",
        descriptiveText: "Password must be at least 8 characters long and contain one uppercase letter, one lowercase letter, one number, and one special character"
      }
    }
  }; 
    
export function AuthStateApp () {
  const useCookies = useSelector(isCookieEnabled);
  if(!useCookies) {
    Amplify.configure(getAuthConfig({useCookies: false}));
}
  const user_details = useSelector(selectUser);
  const authState = useSelector(selectAuthState);
  const dispatch = useDispatch();  
  const { route } = useAuthenticator(context => [context.route]);
  const { authStatus } = useAuthenticator(context => [context.authStatus]);

    React.useEffect(() => { 
        if (route === 'authenticated') {
        const getUser = async () =>
        await Auth.currentAuthenticatedUser().then((user) => {
        dispatch(setUser(user));
        dispatch(setAuthState(authStatus));
        })
        .catch((error) => {
            console.log(error);
        })
        getUser();
        }
    }, [route, authStatus, dispatch]);
    return authState === route && user_details ? <Redirect to="/home"/> : (
                <>
                <Row className='pt-5'>
                <Col></Col>
                <Col>
                  <Authenticator
                    formFields={formFields}>
                  </Authenticator>    
                  <div style={{float: "right"}}><TermsLink/></div>
                  </Col>
                <Col></Col>
                </Row>
                </>
)}