import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { NavLink, useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Form, Col, Container, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { showError } from '../messages/messagesSlice';
import { Link } from "react-router-dom";
import { showSuccess} from '../messages/messagesSlice';
import { useDispatch } from 'react-redux';

export function ChangePassword() {

  function useFormFields(initialState) {
    const [fields, setValues] = useState(initialState);
  
    return [
      fields,
      function(event) {
        setValues({
          ...fields,
          [event.target.id]: event.target.value
        });
      }
    ];
  }

  const history = useHistory();
  const dispatch = useDispatch();
  const [isChanging, setIsChanging] = useState(false);
  const [error, setError] = useState(false);

  const [fields, handleFieldChange] = useFormFields({
    password: "",
    oldPassword: "",
    confirmPassword: "",
  });

  function validateForm() {
    return (
      fields.oldPassword.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  async function handleClick(event) {
    
    event.preventDefault();
    setIsChanging(true);

    Auth.currentAuthenticatedUser()
    .then((user) => new Promise((resolve, reject) => {
        user.changePassword(
          fields.oldPassword,
          fields.password,
          error => {
            if (error) {
                return reject(error);
            }
            resolve();
          });
    }))   
    .then(()=>{
        dispatch(showSuccess({ "message": "Password changed successfully." }));
        history.push("/settings");
    })
    .catch((error) => {     
        dispatch(showError({ "message": error.message }));
        setIsChanging(false);
        setError(error);
    });
  }

  return (
    <div>
      <Container className="pt-5">
        <Card>
          <Card.Header style={{backgroundColor: "#FBAA34", color: "white"}}><h3>Change Password</h3></Card.Header>
          <Card.Body>
              <Form onSubmit={handleClick}>
                <Form.Group as={Row} controlId="oldPassword">
                  <Form.Label column sm="8">
                    Old Password
                  </Form.Label>
                  <Col sm="10">
                  <Form.Control
                    type="password"
                    onChange={handleFieldChange}
                    value={fields.oldPassword}
                  />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="password">
                  <Form.Label column sm="8">
                    New Password
                  </Form.Label>
                  <Col sm="10">
                  <Form.Control
                    type="password"
                    onChange={handleFieldChange}
                    value={fields.password}
                  />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="confirmPassword">
                  <Form.Label column sm="8">
                    Confirm Password
                  </Form.Label>
                  <Col sm="10">
                  <Form.Control
                    type="password"
                    onChange={handleFieldChange}
                    value={fields.confirmPassword}
                  />
                  </Col>
                </Form.Group>
                <div style={{float: "right"}} className="pt-2">
                  <Button
                    variant="info"
                    disabled={!validateForm()}
                    onClick={!isChanging ? handleClick : null}
                  >
                    {isChanging? 'Loading…' : 'Change Password'}
                  </Button>
                </div>
                <div style={{float: "right"}} className="pt-2 pr-2">
                  <NavLink to = {`/home/profile`}>
                    <Button
                      variant='danger'
                    >Back to User Profile
                    </Button>
                  </NavLink>
                </div>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </div> 
  );
}