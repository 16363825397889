import { createSlice } from '@reduxjs/toolkit';
import API from '../../services/api';
import {SURVEYS_API_URL} from '../../config/service';
import { showError } from '../messages/messagesSlice';

const initialState = {
  eqportalaccess: false
}

export const eqPortalAccessSlice = createSlice({
  name: 'eqportalaccess',
  initialState,
  reducers: {
    setEqPortalAccess: (state, action) => {
        if (action.payload)
        {
          state.eqportalaccess = action.payload
        }
    }
  }
});

export const { setEqPortalAccess } = eqPortalAccessSlice.actions;

export const eqPortalAccess = () => dispatch => {
    try {
          API.get(`${SURVEYS_API_URL}/v1/surveys/eqportal/`)
          .then(function(response) {
            dispatch(setEqPortalAccess(response.data))
          });
    }
    catch(error) {
        dispatch(showError({ "message": `Verify EQ Portal Access failed. ${error.message}` }));
    };
};

export const selectEqPortalAccess = state => state.eqportalaccess.eqportalaccess;

export default eqPortalAccessSlice.reducer;
