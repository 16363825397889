import { createSlice } from '@reduxjs/toolkit';
import API from '../../services/api';
import {SURVEYS_API_URL} from '../../config/service';
import { showError } from '../messages/messagesSlice';

export const allocatedSurveyListSlice = createSlice({
  name: 'allocatedsurveylist',
  initialState: {
    allocatedsurveylist: [],
  },
  reducers: {
    setAllocatedSurveyList: (state, action) => {
        if (action.payload)
        {
            state.allocatedsurveylist = action.payload;
            state.isLoadingAllocatedSurveys = false;
        }
    }
  },
});

export const { setAllocatedSurveyList } = allocatedSurveyListSlice.actions;

export const listAllocatedSurveys = () => dispatch => {
    try {
          API.get(`${SURVEYS_API_URL}/v1/surveys/allocated`)
          .then((response) => dispatch(setAllocatedSurveyList(response.data.Items)));
    }
    catch(error) {
        dispatch(showError({ "message": `List surveys failed. ${error.message}` }));
    };
};

export const selectAllocatedSurveys = state => state.allocatedsurveylist.allocatedsurveylist;
export default allocatedSurveyListSlice.reducer;
