import { createSlice } from '@reduxjs/toolkit';
import API from '../../services/api';
import {BUSINESS_API_URL} from '../../config/service';
import { showError, showSuccess } from '../messages/messagesSlice';

export const userAffiliationSlice = createSlice({
  name: 'affiliateuser',
  initialState: {
    value: '',
    affiliateMessage: false,
    unaffiliateMessage: false
  },
  reducers: {
    affiliationRequestStatus: (state, action) => {
      state.value = action.payload;
    },
    userAffiliated: state => {
      state.affiliateMessage = true
    },
    userUnaffiliated: state => {
      state.unaffiliateMessage = true
    },
    resetAffiliationMessage: state => {
      state.affiliateMessage = false
    },
    resetUnallocatedMessage: state => {
      state.unaffiliateMessage = false
    }
  },
});

export const { affiliationRequestStatus, userAffiliated, userUnaffiliated, resetAffiliationMessage, resetUnallocatedMessage } = userAffiliationSlice.actions;

export const requestAffiliation = (username) => dispatch => {
    API.post(`${BUSINESS_API_URL}/v1/business/affiliate/`, {
        username: username
      }).then((response) => {
        dispatch(showSuccess({ "message": "This request has been sent successfully." }));
        dispatch(userAffiliated())
    }).catch((error) => {
        dispatch(showError({ "message": `Affiliation request failed. ${error.response.data.message}` }));
    });
};

export const selectAffiliationRequestStatus = state => state.affiliateuser.value;

export const selectAffiliatedMessage = state => state.affiliateuser.affiliateMessage
export const selectUnaffiliatedMessage = state => state.affiliateuser.unaffiliateMessage

export default userAffiliationSlice.reducer;
