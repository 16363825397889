import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { useAuthenticator } from "@aws-amplify/ui-react";
import Card from 'react-bootstrap/Card';
import { listAllocatedSurveys, selectAllocatedSurveys } from '../allocatedsurveylist/allocatedSurveyListSlice';
import { listAffiliatedOrgs } from '../affiliatedorgslist/affiliatedOrgsListSlice';
import { listSurveys, selectSurveys } from '../surveylist/surveyListSlice';
import { listSurveyHistory } from '../surveyhistory/surveyHistorySlice';
import { SurveyHistoryPreview } from '../surveyhistory/surveyHistoryPreview';
import { selectAuthState, selectUser } from '../auth/authSlice';
import { AllocatedSurveyData } from '../allocatedsurveydata/allocatedSurveyData';
import AllocatedSurveyDataCso from '../allocatedsurveydata/allocatedSurveyDataCso';
import AllocatedSurveyDataCsoAdmin from '../allocatedsurveydata/allocatedSurveyDataCsoAdmin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { selectEqPortalAccess, eqPortalAccess } from '../eqportalaccess/eqPortalAccessSlice';
import { AffiliatedOrgs } from '../affiliatedorgslist/affiliatedOrgs';
import { AffiliatedPendingOrgs } from '../affiliatedorgslist/affiliatedPendingOrgs';
import { unlistExternalUserTasks } from '../externalsurveyallocation/externalUserTasksSlice';


export function HomePage() {

    let admin;
    let cso;
    let external;
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const authState = useSelector(selectAuthState);
    const eqportalaccess = useSelector(selectEqPortalAccess);
    const surveys = useSelector(selectSurveys);
    const allocatedsurveys = useSelector(selectAllocatedSurveys);
    const { route } = useAuthenticator(context => [context.route]);

    if (user) {
        const userType = user.groups && user.groups.toString();
        if(typeof userType !== "undefined")
        {
            const strIndex = userType.indexOf('ADMINS');
            if(strIndex === -1) {
                admin = false;
            } else {
                admin = true;
            }

            const strIndexCSO = userType.indexOf('CSO');
            if(strIndexCSO === -1) {
                cso = false;
            } else {
                cso = true;
            }

            const strIndexExternal = userType.indexOf('EXTERNAL');
            if(strIndexExternal === -1) {
                external = false;
            } else {
                external = true;
            }
        }
    }
    
    React.useEffect(() => {

        if(authState === route){

            dispatch(eqPortalAccess());

            if(external === false)
            {
                if(admin)
                {
                    dispatch(listSurveys());
                }
                else
                {
                    dispatch(listAllocatedSurveys());
                }
                if(eqportalaccess)
                {
                    dispatch(listSurveyHistory());
                }
            }
            else
            {
                dispatch(listAffiliatedOrgs());
                dispatch(unlistExternalUserTasks());
            }
        }

    }, [authState, route, dispatch, admin, eqportalaccess, external]);

    // React.useEffect(() => {

    //     if(authState === route && external){
    //         dispatch(listAffiliatedOrgs());
    //         dispatch(unlistExternalUserTasks());
    //     }

    // }, [authState, external, route, dispatch]);

    // React.useEffect(() => {

    //     if(authState === route && eqportalaccess && external === false){
    //         dispatch(listSurveyHistory());
    //     }

    // }, [authState, route, dispatch, external, eqportalaccess]);

    // React.useEffect(() => {
    //     if(authState === route && external===false){
    //         dispatch(listSurveys());
    //     }    
    // }, [businessAdminDependencyExpression, route, authState, dispatch, external]);

    // React.useEffect(() => {
    //     if(authState === route && external===false){
    //         dispatch(listAllocatedSurveys());
    //     }
    // }, [businessUserDependencyExpression, authState, route, dispatch, external]);

    function DataToDisplay() {
        if (admin)
        {
            if(cso)
            {
                return (
                    <AllocatedSurveyDataCsoAdmin />          
                )
            }
            else return <AllocatedSurveyData surveys={surveys}/>;
        }
        else if (cso)
        {
            return (
                <Col style={{ paddingLeft: 50, paddingRight: 50 }}>
                    <Container fluid className= 'pt-5'>
                        <Card>
                        <Card.Header style={{backgroundColor: "#FBAA34", color: "white"}}>
                                <h3 style={{textAlign:'left', float:'left'}}>Available Surveys &nbsp;</h3>
                            </Card.Header>
                            <Card.Body>
                                <AllocatedSurveyDataCso />
                            </Card.Body>
                        </Card>
                    </Container>
               </Col>
                )
        }
        else if (external)
        {
            return (
                <Row>
                    <Col style={{ paddingLeft: 50, paddingRight: 50 }}>
                        <Container fluid className= 'pt-5'>
                            <Card>
                            <Card.Header style={{backgroundColor: "#FBAA34", color: "white"}}>
                                    <h3 style={{textAlign:'left', float:'left'}}>Affiliated Organisations &nbsp;</h3>
                                </Card.Header>
                                <Card.Body>
                                    <AffiliatedOrgs/>
                                </Card.Body>
                            </Card>
                        </Container>
                    </Col>
                    <Col style={{ paddingLeft: 50, paddingRight: 50 }}>
                        <Container fluid className= 'pt-5'>
                            <Card>
                                <Card.Header style={{backgroundColor: "#1D345C", color: "white"}}>
                                    <h3 style={{textAlign:'left', float:'left'}}>Affiliation Requests &nbsp;</h3>
                                </Card.Header>
                                <Card.Body>
                                        <AffiliatedPendingOrgs/>
                                </Card.Body>
                            </Card>
                        </Container>
                    </Col>
                </Row>
                )
        }
        else return <AllocatedSurveyData surveys={allocatedsurveys}/>;
    }

  return (
            <Row>
                <Col>
                  <DataToDisplay />
                </Col>
                {eqportalaccess && !cso && !external &&
                    <Col>
                        <Container className= 'pt-5'>
                            <Card>
                                <Card.Header style={{backgroundColor: "#1D345C", color: "white"}}>
                                    <h3 style={{textAlign:'left', float:'left'}} >Survey History (Preview) &nbsp;</h3>
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 150, hide: 300 }}
                                        overlay={(
                                        <Popover id="popover-basic">
                                            <Popover.Content>
                                                The information in this table may take up to 1 business day to update. 
                                            </Popover.Content>
                                        </Popover>
                                        )} >
                                        <h5 style={{textAlign:'right', float:'left'}}><FontAwesomeIcon icon={faInfoCircle}/></h5>
                                    </OverlayTrigger>
                                </Card.Header>
                                <Card.Body style={{backgroundColor: "white"}}>
                                    <SurveyHistoryPreview />
                                </Card.Body>
                            </Card>
                        </Container>
                    </Col>
                }   
            </Row>  
  );
}
