import { createSlice } from '@reduxjs/toolkit';

export const alertSlice = createSlice({
  name: 'alert',
  initialState: {
    title: "Confirm",
    message: "",
    closeButtonText: "Close",
    confirmButtonText: "Confirm",
    alertClose: false,
    alertConfirm: false,
    visible: false,
    //error,info, warning
    type: '',
    //Async action which you want to do perfrom
    callBackActionId: ""
  },
  reducers: {
    showAlert: (state, action) => {
        if(action.payload) {
            if(action.payload.title) {
                state.title = action.payload.title;
            } 
            state.message = action.payload.message || "";
            if(action.payload.closeButtonText) {
                state.closeButtonText = action.payload.closeButtonText;;
            }
            if(action.payload.confirmButtonText) {
                state.confirmButtonText = action.payload.confirmButtonText;
            }
            if(action.payload.type) {
                state.type = action.payload.type;
            }
            state.visible = true;
            if(action.payload.callBackActionId) {
                state.callBackActionId = action.payload.callBackActionId;
            } else {
                state.callBackActionId = '';
            }
        }
    },
    hideAlert: (state, action) => {
        state.visible = true;
    },
    closeAlert: (state, action) => {
        state.alertConfirm = true;
        state.visible = false;
    },
    confirmAlert: (state, action) => {
        state.alertClose = true;
        state.visible = false;
    }
  },
});

export const { showAlert, hideAlert, closeAlert, confirmAlert } = alertSlice.actions;

export const selectCurrentAlertModal = state => state.alert;

export default alertSlice.reducer;
