import React  from 'react';
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";

export const SurveySubmission = (props) => {

  let tableData = [{}]

  const surveySubs = props.submissions

  function dateFormat(longDate)
  {
    if(longDate && longDate !== "" && longDate!== " ")
    {
      const unixStamp = Date.parse(longDate);
      const newDate = new Date(unixStamp).toISOString().substring(0, 10)
      const newTime = new Date(unixStamp).toISOString().substring(11, 19)
      const readableDate = newDate + " " + newTime;
      return readableDate;
    }
    else return null;
  } 

  if (surveySubs && surveySubs.length > 0)
  {
    surveySubs.map((surveyRow, key) => {
    tableData.push({  
      survey_title: surveyRow.Data[0].VarCharValue,  
      survey_user: surveyRow.Data[1].VarCharValue,
      survey_started: dateFormat(surveyRow.Data[2].VarCharValue),
      survey_submitted: dateFormat(surveyRow.Data[3].VarCharValue)
      })
    })
  }

  const columns = [{  
    Header: 'Survey Title',  
    accessor: 'survey_title',
    },{
    Header: 'User',  
    accessor: 'survey_user'
    },{  
    Header: 'Started at:',  
    accessor: 'survey_started',
    },{  
    Header: 'Submitted at:',  
    accessor: 'survey_submitted',
    }
  ]  

  return (
      <ReactTable  
        data={tableData}  
        columns={columns}  
        className='-striped -highlight'
        minRows={0} 
        defaultPageSize = {4}  
        pageSizeOptions = {[2,4,6,8]}  
      /> 
    )
};

