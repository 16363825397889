import { createSlice } from '@reduxjs/toolkit';
import API from '../../services/api';
import {BUSINESS_API_URL} from '../../config/service';
import { showError, showSuccess } from '../messages/messagesSlice';

export const sendExtSlice = createSlice({
  name: 'sendmessageext',
  initialState: {
    messageExt: '',
    isLoadingExt: false,
    errorExt: false,
    sentExt: false,
    requestExt: {}
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.errorExt = action.message;
      state.isLoadingExt = false;
    },
    success: (state, action) => {
      state.isLoadingExt = false;
      state.sentExt = true;
      state.messageExt = action.message
    },
    resetState: state => {
      state.messageExt = '';
      state.sentExt = false;
      state.errorExt = false;
      state.requestExt = {};
    },
    registerSentRequest: (state, action) => {
      state.requestExt = {
        ...state.requestExt,
        [action.payload.surveyId] : true
      }
      console.log("Setting request ", JSON.stringify(state.requestExt));
    },
    deleteSentRequest: (state, action) => {
      state.requestExt = {
        ...state.requestExt,
        [action.payload.surveyId] : false
      }
      console.log("Setting request ", JSON.stringify(state.requestExt));
    }
  },
});

export const { startLoading, hasError, success, registerSentRequest, deleteSentRequest, resetState} = sendExtSlice.actions;

export const selectSendMessage = state => state.sendmessageext;

export const resetMessageForm = () => dispatch => {dispatch(resetState())};

export const sendMessageAction = (comment, email, reCaptcha) => dispatch => {
  dispatch(startLoading());
  API.post(`${BUSINESS_API_URL}/v1/business/contacts/external`, {
      comment,
      email,
      reCaptcha
    }).then((response) => {
      dispatch(showSuccess({ "message": "Message was sent successfully." }));
      dispatch(success({message: comment}))
  }).catch((error) => {
      dispatch(showError({ "message": `We ran into an issue sending your message. ${error.message}` }));
      dispatch(hasError({message: error.message}))
  });
};

export default sendExtSlice.reducer;
