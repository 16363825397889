export const Navigation={

    User_Management:[
        "/home/users",
        "/home/users/details",
        "/home/users"
    ],
    External_User_Management:[
        "/home/external"
    ],
    Survey_Management:[
        "/home/surveys",
        "/home/users/manage",
    ],
    All_Users:[
        "/home/userlist"
    ],
    Home:[
        "/home/download",
        "/home/upload",
        "/home/uploadcso",
        ""
    ],
    User_Profile:[
        "/home/profile",
        "/home/changepwd",
    ],
    Contact:[
        "/home/contacts"
    ],
    Survey_History:[
        "/home/history"
    ]

}

