import React from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { listTemplates } from './listTemplatesSlice'
import { TemplateData } from './listTemplateData'
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { selectSurveys } from '../surveylist/surveyListSlice';
import { selectAllocatedSurveys } from '../allocatedsurveylist/allocatedSurveyListSlice';
import { selectCsoAllSurveys } from "../csoallsurveys/csoAllSurveysSlice";
import { selectExternalUserTasks } from '../externalsurveyallocation/externalUserTasksSlice';
import { selectUser } from "../auth/authSlice";

export function DownloadTemplate() {

  let surveys;
  const location = useLocation();
  const history = useHistory();
  const selectedSurvey = location.pathname.split('download/')[1]
  const user = useSelector(selectUser);
  // const templates = useSelector(selectTemplates)
  const dispatch = useDispatch();
  const csosurvey = useSelector(selectCsoAllSurveys);
  const businessadminsurvey = useSelector(selectSurveys);
  const businessusersurvey = useSelector(selectAllocatedSurveys)
  const externalsurvey = useSelector(selectExternalUserTasks);

  if (user){
    const userGroup = user.groups && user.groups.toString();
    if(userGroup.includes("CSO_ADMINS"))
    {
      surveys = csosurvey
    }
    else if(userGroup.includes("EXTERNAL_USERS"))
    {
      surveys = externalsurvey
    }
    else if(userGroup.includes("BUSINESS_ADMINS"))
    {
      surveys = businessadminsurvey
    }
    else
    {
      surveys = businessusersurvey
    }
  }

  function byKeyPK(arr, key) {
    for ( var i=0, L=arr.length; i<L; i++ ) {
      if ( arr[i].survey_id === key ) {
        return arr[i].PK;
      }
    }
  }

  React.useEffect(() => {

      dispatch(listTemplates(byKeyPK(surveys, selectedSurvey)));

  }, [dispatch, selectedSurvey, surveys]);  
  
    return (
          <div className='feature-wrapper pb-5 mt-5 mt-lg-0'>
            <Row>
              <Col>
                <Container className= 'pt-5'>
                    <Card>
                      <Card.Header style={{backgroundColor: "#45C1C0", color: "white"}}><h3>Available Templates</h3></Card.Header>
                      <Card.Body>
                          <TemplateData />
                      </Card.Body>
                      </Card>
                        <div style={{float: "right"}} className="pt-2 pr-2">
                          <Button
                            onClick={() => history.goBack()}
                            variant='info'
                            >Back
                          </Button>
                        </div>
                  </Container>
                </Col>
            </Row>
          </div>
    );
}
