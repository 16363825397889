import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { showError } from '../messages/messagesSlice';
import { showAlert } from '../alert/alertSlice';
import { selectTriggerRevokeAffiliation, revokeAffiliationTriggered, revokeAffiliationAction } from './revokeAffiliationSlice';

export const RevokeAffiliation = (props)  => {
    const [error, setError] = useState(false);
    const [status, setStatus] = useState(false);
    const revokeAffiliation = useSelector(selectTriggerRevokeAffiliation);
    const dispatch = useDispatch();
    const username = props.username

    const revokeThisAffiliation = () => {
        dispatch(showAlert({
            title: "Warning",
            message: `Please confirm that you would like to revoke this affiliation. All tasks assigned to this user will be unassigned and you will no longer be able to assign tasks to them.`,
            closeButtonText: "Close",
            confirmButtonText: "Confirm",
            type: 'error',
            callBackActionId: 'revokeaffiliation/revokeAffiliation'
        }));
    };
    
    React.useEffect(() => {
        if(revokeAffiliation) {
            dispatch(revokeAffiliationTriggered());
            dispatch(revokeAffiliationAction(username));
            setStatus(true)
        };
    }, [revokeAffiliation])

    if(error) {
        dispatch(showError({ "message": "Failed to revoke affiliation." }));
    } 
    else if (status) {
        return <Redirect to='/home/external' />;
    }
     else {
        return (
            <div>
                <Button onClick={revokeThisAffiliation} variant="danger">Revoke Affiliation</Button>
            </div>
        );
    }
}
