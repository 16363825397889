import API from '../../services/api';
import {SURVEYS_API_URL} from '../../config/service';
import { showError, showSuccess } from '../messages/messagesSlice';
import { surveyUnallocated } from "./allocateSurveySlice";

export const unallocateSurvey = (username, surveyString, surveyName) => dispatch => {
    API.put(`${SURVEYS_API_URL}/v1/surveys/unallocate/user/${surveyString}`, {
        username: username,
        surveyName: surveyName
      }).then((response) => {
        dispatch(showSuccess({ "message": "This survey has been unallocated successfully from: " + username }));
        dispatch(surveyUnallocated())
    }).catch((error) => {
        dispatch(showError({ "message": `Survey unallocation failed. ${error.message}` }));
    });
};
