import React from 'react';
import {
  Switch,
  useRouteMatch
} from "react-router-dom";
import { UserProfile } from '../userprofile/UserProfile';
import { HomePage } from '../homepage/HomePage';
import { PrivateRoute } from '../../components/PrivateRoute';
import { ChangePassword } from '../changepassword/ChangePassword';
import { UserManagement } from '../usermanagement/UserManagement';
import { ExternalUserManagement } from '../usermanagement/ExternalUserManagement';
import { ExternalUserTasks } from '../externalsurveyallocation/ExternalUserTasks';
import { ExternalSurveyAllocation } from '../externalsurveyallocation/externalSurveyAllocation';
import { ManageSurveyUsers } from '../managesurveyusers/ManageSurveyUsers';
import { CsoAllUsers } from '../csoallusers/csoAllUsers';
import { SurveyManagement } from '../surveymanagement/SurveyManagement';
import { FileUpload } from '../fileupload/fileUpload';
import { DownloadTemplate } from '../downloadtemplate/downloadTemplate';
import { Contacts } from '../contacts/Contacts';
import { ContactExternal } from '../contacts/ContactExternal';
import { SurveyHistory } from '../surveyhistory/surveyHistory';
import { ManageUserDetails } from '../manageuserdetails/ManageUserDetails';
import { FileUploadCSO } from '../fileuploadcso/fileUploadcso';

export function Home() {

  let { path } = useRouteMatch();

  return (
    <div>
      <Switch>
        <PrivateRoute path={`${path}/profile`}>
          <UserProfile/>
        </PrivateRoute>
        <PrivateRoute path={`${path}/history`}>
          <SurveyHistory/>
        </PrivateRoute>
        <PrivateRoute path={`${path}/upload`}>
          <FileUpload/>
        </PrivateRoute>
        <PrivateRoute path={`${path}/uploadcso`}>
          <FileUploadCSO/>
        </PrivateRoute>
        <PrivateRoute path={`${path}/download`}>
          <DownloadTemplate/>
        </PrivateRoute>
        <PrivateRoute path={`${path}/changepwd`}>
          <ChangePassword/>
        </PrivateRoute>
        <PrivateRoute path={`${path}/users/manage`}>
          <ManageSurveyUsers/>
        </PrivateRoute>
        <PrivateRoute path={`${path}/userlist`}>
          <CsoAllUsers/>
        </PrivateRoute>
        <PrivateRoute path={`${path}/users/details`}>
          <ManageUserDetails loadState={false}/>
        </PrivateRoute>
        <PrivateRoute path={`${path}/users`}>
          <UserManagement/>
        </PrivateRoute>
        <PrivateRoute path={`${path}/external`}>
          <ExternalUserManagement/>
        </PrivateRoute>
        <PrivateRoute path={`${path}/tasks/external`}>
          <ExternalUserTasks/>
        </PrivateRoute>
        <PrivateRoute path={`${path}/allocate/external`}>
          <ExternalSurveyAllocation/>
        </PrivateRoute>
        <PrivateRoute path={`${path}/surveys`}>
          <SurveyManagement/>
        </PrivateRoute>
        <PrivateRoute path={`${path}/contacts`}>
          <Contacts/>
        </PrivateRoute>
        <PrivateRoute path={`${path}/contact/external`}>
          <ContactExternal/>
        </PrivateRoute>
        <PrivateRoute path={path}>
          <HomePage/>
        </PrivateRoute>
      </Switch>
    </div>
  );
}
